// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Sidebar_height_55__n7UKU{
    
    height: 55px;
    
}

i{
    cursor: pointer;
}
.Sidebar_overflow__rKQs\\+ {
    overflow-y: auto !important;

  }







/* .nav-pills .nav-link.active span ,.nav-pills .nav-link.active i{
    color: white !important;
    background-color: blueviolet !important;
} */


`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.module.css"],"names":[],"mappings":";AACA;;IAEI,YAAY;;AAEhB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,2BAA2B;;EAE7B;;;;;;;;AAQF;;;GAGG","sourcesContent":["\n.height_55{\n    \n    height: 55px;\n    \n}\n\ni{\n    cursor: pointer;\n}\n.overflow {\n    overflow-y: auto !important;\n\n  }\n\n\n\n\n\n\n\n/* .nav-pills .nav-link.active span ,.nav-pills .nav-link.active i{\n    color: white !important;\n    background-color: blueviolet !important;\n} */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"height_55": `Sidebar_height_55__n7UKU`,
	"overflow": `Sidebar_overflow__rKQs+`
};
export default ___CSS_LOADER_EXPORT___;
