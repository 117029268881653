import React from 'react'
import EditePurchases from '../../Purchases/EditePurchases'

export default function EditePurchasesUser() {
  return (
 <>
 <EditePurchases/>
 </>
  )
}
