import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';



export default function AddDoctors() {
    let { accessToken } = useContext(AuthContext);
    //selcting form  to reset the form after submtion
    const formRef = useRef(null);

    let [isLoading, setIsLoading] = useState(false);
    let [doctorsData, setDoctorsData] = useState({
        name: '',
        specialization: '',
        degree: '',
        contactInfo: '',
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setDoctorsData(prevState => ({ ...prevState, [name]: value }));
    };
    let sendDoctorsDataToApi = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/doctors`, doctorsData, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                formRef.current.reset();
                setDoctorsData({
                    name: '',
                    specialization: '',
                    degree: '',
                    contactInfo: '',
                })
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
            }).catch((errors) => {
                console.log(errors);
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validateDoctorsDataForm = () => {
        const schema = Joi.object({
            name: Joi.string().allow(''),
            specialization: Joi.string().allow(''),
            degree: Joi.string().allow(''),
            contactInfo: Joi.string().allow(''),

        });
        return schema.validate(doctorsData, { abortEarly: false });
    };
    let submitDoctorsDataForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateDoctorsDataForm();
        if (!validation.error) {
            sendDoctorsDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add doctors</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة طبيب </h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitDoctorsDataForm} >
                    <div className="row gy-3">
                        <div className="col-md-5">
                            <label htmlFor="name" className='form-label'>اسم الطبيب </label>
                            <input
                                type="text"
                                className='form-control'
                                name="name"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="specialization" className='form-label'>التخصص</label>
                            <input
                                type="text"
                                className='form-control'
                                name="specialization"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="degree" className='form-label'> الدرجة العلمية  </label>
                            <input
                                type="text"
                                className='form-control'
                                name="degree"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="contactInfo" className='form-label'> العنوان والهاتف</label>
                            <input
                             type="text"
                              className='form-control' 
                               name="contactInfo"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                            </button>
                        </div>

                    </div>
                </form >
            </div >
        </>
    )
}
