import React, { useContext, useEffect, useState } from 'react'
import AddHoursOrDay from './AddHoursOrDay'
import AddDiscount from './AddDiscount'
import AddDebt from './AddDebt'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Pagination from '../Pagination/Pagination';
import AdjusmentsActions from './AdjusmentsActions';


export default function MonthlyAccounts() {
    let { accessToken } = useContext(AuthContext);
    let [monthlyAccounts, setMonthlyAccounts] = useState([]);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [employees, setEmployees] = useState([]);
    let [employeesOptions, setEmployeesOptions] = useState([]);
    let getEmployeesData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees?noPaginate=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setEmployees(data.data);
        } catch (error) {
            toast.error('حدث خطأ ما.')
        }
    };
    useEffect(() => {
        getEmployeesData()
        getEmployeeCategory()
        getBranches()
    }, []);
    useEffect(() => {
        let mapEmployees = employees?.map((emp) => ({
            value: `${emp.id}`,
            label: `${emp.name}`,
        }));
        setEmployeesOptions(mapEmployees);
    }, [employees]);

    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }
    let [filterMonth, setFilterMonth] = useState('');
    function handleMonthChange(event) {
        setFilterMonth(event?.target?.value);
    }
    let [filterEmployeeId, setFilterEmployeeId] = useState('');
    function handleEmployeeChange(selectedOption) {
        setFilterEmployeeId(selectedOption.value)
    }
    let [filterCategoryId, setFilterCategoryId] = useState('');
    function handleCategoryChange(event) {
        setFilterCategoryId(event?.target?.value);
    }
    let [filterBranchId, setFilterBranchId] = useState('');
    function handleBranchIdChange(event) {
        setFilterBranchId(event?.target?.value);
    }
    let [totals, setTotals] = useState('');
    let getMonthlyAccountsData = async (page = 1) => {
        let monthlyAccounts;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/monthly-accounts/filter?`;
        if (filterEmployeeId !== undefined && filterEmployeeId.length > 0) {
            urlApi += `employee_id=${filterEmployeeId}&`
        }
        if (filterCategoryId !== undefined && filterCategoryId.length > 0) {
            urlApi += `category_id=${filterCategoryId}&`
        }
        if (filterBranchId !== undefined && filterBranchId.length > 0) {
            urlApi += `branch_id=${filterBranchId}&`
        }
        if (filterMonth !== undefined && filterMonth.length > 0) {
            urlApi += `month_year=${filterMonth}&`
        }
        urlApi += `page=${page}&`
        try {
            monthlyAccounts = await axios.get(urlApi, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            if (monthlyAccounts) {
                setTotals(monthlyAccounts.data)
                setMonthlyAccounts(monthlyAccounts.data.data);
                setPagination(monthlyAccounts.data.meta); // Set pagination data
                setCurrentPage(page); // Set current page
            }
        } catch (error) {
            console.log(error);
            toast.error('حدث خطأ ما')
        }

    };
    useEffect(() => { getMonthlyAccountsData() },
        [filterCategoryId, filterBranchId, filterEmployeeId, filterMonth]);
    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }

    let showMounthlyAccount = () => {
        if (monthlyAccounts.length > 0) {
            return (
                <>
                    <div className='bg-white text-center w-75 p-1 mb-1 rounded m-auto'>
                        <p><strong>  مجموع المديونية:</strong> <span>{totals?.total_debts} </span></p>
                        <p><strong>  مجموع صافي الراتب:</strong> <span>{totals?.total_net_salary} </span></p>
                        <p><strong>مجموع صافي الراتب + المديونية:</strong> <span>{totals?.total_netSalary_and_Debts}</span></p>
                    </div>
                    <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                        <table dir="rtl" className='table table-bordered  table-hover text-center align-middle table-responsive-list '>
                            <thead className='table-primary  no-wrap-heading'>
                                <tr>
                                    <th>شهر</th>
                                    <th>سنة</th>
                                    <th>كود</th>
                                    <th>اسم الموظف</th>
                                    <th>الوظيفة</th>
                                    <th>سعر الساعة</th>
                                    <th> عدد ساعات الحضور</th>
                                    <th>قيمة ساعات الحضور </th>
                                    <th> عدد الساعات الإضافية</th>
                                    <th>قيمة الساعات الإضافية </th>
                                    <th>إجمالي الراتب </th>
                                    <th> الحافز الثابت </th>
                                    <th> حوافز </th>
                                    <th>إجمالي الإستحقاقات </th>
                                    <th> عدد  ساعات الخصم</th>
                                    <th> قيمة  ساعات الخصم</th>
                                    <th> عدد  ساعات التأخير</th>
                                    <th>مديونية </th>
                                    <th>جزاءات </th>
                                    <th>إجمالي الإستقطاعات </th>
                                    <th>صافي الراتب</th>

                                </tr>
                            </thead>
                            <tbody>
                                {monthlyAccounts.map((account) => (
                                    <tr key={account.id}>
                                        <td data-label="شهر" >{account?.month}</td>
                                        <td data-label="سنة" >{account?.year}</td>
                                        <td data-label="كود" >{account?.employee?.code}</td>
                                        <td data-label="اسم الموظف" >{account?.employee?.name}</td>
                                        <td data-label="الوظيفة" >{account?.employee?.category?.name}</td>
                                        <td data-label="سعر الساعة" >{account?.employee?.hourRate}</td>
                                        <td data-label="عدد ساعات الحضور" >{account?.working_hours}</td>
                                        <td data-label="قيمة ساعات الحضور" >{account?.working_hours_value}</td>
                                        <td data-label="عدد الساعات الإضافية" >{account?.additional_hours}</td>
                                        <td data-label="قيمة الساعات الإضافية" >{account?.additional_hours_value}</td>
                                        <td data-label="إجمالي الراتب" className='table-success'>{account?.base_salary}</td>
                                        <td data-label="الحافز الثابت" >{account?.employee?.constantIncentive}</td>
                                        <td data-label="حوافز" >{account?.incentives}</td>
                                        <td data-label="إجمالي الإستحقاقات" className='table-danger' >{account?.total_entitlements}</td>
                                        <td data-label="عدد ساعات الخصم" >{account?.discounted_hours}</td>
                                        <td data-label="إجمالي الإستحقاقات" >{account?.discounted_hours_value}</td>
                                        <td data-label="عدد ساعات التأخير" >{account?.numOfHoursLate}</td>
                                        <td data-label="مديونية" >{account?.debts}</td>
                                        <td data-label="جزاءات" >{account?.cash_penalties}</td>
                                        <td data-label="إجمالي الإستقطاعات" className='table-danger' >{account?.total_deductions}</td>
                                        <td data-label=" صافي الراتب" className='table-success' >{account?.net_salary}</td>

                                    </tr>

                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {monthlyAccounts.length <= 0 && filterCategoryId.length <= 0 && filterEmployeeId.length <= 0 && filterMonth.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-3x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد بيانات متاحة</div>
                    }
                </div>
            )
        }
    }

    //for handle page change
    let handlePageChange = (page) => {
        getMonthlyAccountsData(page);
    };
    return (
        <>
            {/* add houres modal */}
            <button className='btn btn-success  m-2' data-bs-toggle="modal" data-bs-target="#addHoursOrDay" >إضافة ساعات أو يوم </button>
            <div dir='rtl' className="modal fade" id="addHoursOrDay" tabIndex={-1}
                aria-labelledby="addHoursOrDayLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="addHoursOrDayLabel">
                                إضافة ساعات أو يوم أو قيمة معينة
                            </h1>
                        </div>
                        <div className="modal-body ">
                            <AddHoursOrDay />
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add a discount  modal */}
            <button className='btn btn-danger  m-2' data-bs-toggle="modal" data-bs-target="#addDiscount" >إضافة خصم  </button>
            <div dir='rtl' className="modal fade" id="addDiscount" tabIndex={-1}
                aria-labelledby="addDiscountLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="addDiscountLabel">
                                إضافة خصم أو  جزاءات
                            </h1>
                        </div>
                        <div className="modal-body ">
                            <AddDiscount />
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add a debt  modal */}
            <button className='btn btn-warning m-2' data-bs-toggle="modal" data-bs-target="#addDebt" >إضافة مديونية  </button>
            <div dir='rtl' className="modal fade" id="addDebt" tabIndex={-1}
                aria-labelledby="addDebtLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="addDebtLabel">
                                إضافة  مديونية
                            </h1>
                        </div>
                        <div className="modal-body ">
                            <AddDebt />
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* adjusmentsActions */}
            <button className='btn btn-secondary m-2' data-bs-toggle="modal" data-bs-target="#adjusmentsActions" >حركات الإضافة</button>
            <div dir='rtl' className="modal fade" id="adjusmentsActions" tabIndex={-1}
                aria-labelledby="adjusmentsActions" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="adjusmentsActionsLabel">
                                حركات الإضافة  
                            </h1>
                        </div>
                        <div className="modal-body ">
                        <AdjusmentsActions/>
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Filters */}
            <div className="row m-2 gy-2" dir='rtl'>
                <div className="col-md-3">
                    <select name="branch_id" defaultValue={0} className='form-control'
                        onChange={handleBranchIdChange}>
                        <option value={0} hidden disabled>اختر الفرع...</option>
                        {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-3">
                    <Select
                        options={employeesOptions}
                        onChange={handleEmployeeChange}
                        isSearchable={true}
                        placeholder="بحث عن موظف..."
                    />
                </div>
                <div className="col-md-3">
                    <select defaultValue={0} type="text" className='form-control' name="category_id" onChange={handleCategoryChange} >
                        <option value={0} hidden disabled>اختر الوظيفة...</option>
                        {employeeCategory.map((cat) => <option key={cat.id} value={cat.id} >{cat?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="month"
                        className='form-control'
                        onChange={handleMonthChange}
                    />
                </div>
            </div>

            {/* Display Monthly Accounts Data */}

            {(filterEmployeeId.length > 0 || filterCategoryId.length > 0 || filterMonth.length > 0 || filterBranchId.length > 0) && (
                <>
                    <div className="text-center mb-2">
                        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
                    </div>
                    <div>
                        {showMounthlyAccount()}
                    </div>
                </>
            )}



        </>
    )
}
