import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../Context/AuthStore';
import { toast } from 'react-toastify';
import Joi from 'joi';

export default function Home() {
  let { accessToken } = useContext(AuthContext);
  let [anyDate, setAnyDate] = useState({
    start_date: '',
    end_date: ''
  })
  let [ordersNumbers, setOrdersNumbers] = useState('');
  let [users, setUsers] = useState([]);

  // Update input value
  const getInputValue = (event) => {
    const { name, value } = event.target;
    setAnyDate((prevDate) => ({
      ...prevDate,
      [name]: value
    }));
  };

  // Send date to API
  let sendDateToApi = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/specificOrders`, anyDate, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      setOrdersNumbers(res?.data?.totalNumOfOrders);
      setUsers(res?.data?.users);
    }).catch((errors) => {
      try {
        const errorList = errors?.response?.data?.message;
        if (errorList !== undefined) {
          Object.keys(errorList)?.map((err) => {
            errorList[err]?.map((err) => {
              toast.error(err);
            })
          });
        } else {
          toast.error("حدث خطأ ما");
        }
      } catch (error) {
        toast.error("حدث خطأ ما");
      }

    })
  }
  let validateDateForm = () => {
    const schema = Joi.object({
      start_date: Joi.date().required().messages({
        "date.base": `تاريخ البداية مطلوب`,
      }),
      end_date: Joi.date().required().messages({
        "date.base": `تاريخ النهاية مطلوب`,
      }),
    });
    return schema.validate(anyDate, { abortEarly: false });
  };
  // Submit form
  const submitDateForm = (e) => {
    e.preventDefault();
    const validation = validateDateForm();
    if (!validation.error) {
      sendDateToApi();
    } else {
      validation.error.details.forEach((err) => {
        toast.error(err.message);
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>
      <div className='container my-4'>
        <div className='alert text-center fs-4 text-white' style={{ backgroundColor: 'rgb(100, 100, 128)' }}>
          أعداد الأوردرات
        </div>
        <form onSubmit={submitDateForm}>
          <div className='row' dir='rtl'>
            <div className='col-md-4'>
              <label htmlFor="start_date" className='form-label'>من يوم</label>
              <input type="date" name="start_date" id="start_date" className='form-control mb-2' onChange={getInputValue} />
            </div>
            <div className='col-md-4'>
              <label htmlFor="end_date" className='form-label'> إلى يوم</label>
              <input type="date" name="end_date" id="end_date" className='form-control mb-3' onChange={getInputValue} />
            </div>
            <div className='mb-3'>
              <button type='submit' className='btn btn-secondary'>بحث...</button>
            </div>
          </div>
        </form>
        {ordersNumbers && (
          <div className="col-md-3 card text-center m-auto py-4">
            <div className='fs-1'>{ordersNumbers}</div>
            <span className="lead">عدد الأوردرات</span>
          </div>
        )}
        <div className="row" dir='rtl'>
          {users.map((user) => (
            <div key={user.user_id} className='col-md-3 my-2 text-center'>
              <div className='card p-1'>
                <div className='bg-secondary-subtle rounded'>{user.user_name}</div>
                <div className='bg-secondary-subtle rounded my-1'>{user.user_code}</div>
                <div className='bg-secondary-subtle rounded'>{user.user_role}</div>
                <div className='fs-2'>{user.numOfOrders}</div>
                <div className="lead">عدد الأوردرات</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
    )
}
