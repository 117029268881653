import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../Context/AuthStore';
import { toast } from 'react-toastify';

export default function Users() {

  let { accessToken } = useContext(AuthContext);
  let [users, setUsers] = useState([]);
  let [searchText, setSearchText] = useState('');
  function handleSearchChange(event) {
    setSearchText(event.target.value)
  };

  let getUserData = async () => {
    let searchResult;
    try {
      if (searchText !== undefined && searchText.trim().length > 0) {
        searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/search/${searchText.trim()}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
        setUsers(searchResult.data);
      } else {
        searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
        setUsers(searchResult.data.data);
      }
    } catch (error) {
      toast.error('حدث خطأ ما')
    }

  }
  useEffect(() => { getUserData() }, [searchText]);
  //paid all money for all orders for every one
  let makePaidForAllOrders = async (userId) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/payAllOrders/${userId}`, {}, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getUserData()
    }).catch((errors) => {
      toast.error(errors?.response?.data?.message);
    })
  }
  let [userId, setUserId] = useState('')
  let showUsers = () => {
    if (users.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 border table-responsive">
          <table dir="rtl" className='table table-bordered  table-hover text-center table-responsive-list '>
            <thead className='table-primary  no-wrap-heading' >
              <tr >
                <th>رقم</th>
                <th>كود المستخدم</th>
                <th>الاسم</th>
                <th>الفرع</th>
                <th>الوظيفة</th>
                <th>رقم الهاتف</th>
                <th>إجمالي المبلغ</th>
                <th> سداد</th>
                <th>خيارات</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => <tr key={user.id}>
                <td data-label="#">{++index}</td>
                <td data-label="كود المستخدم">{user?.code}</td>
                <td data-label="اسم المستخدم">{user?.name}</td>
                <td data-label="الفرع">{user?.branch?.name}</td>
                <td data-label="الوظيفة">{user?.role == 'admin' ? 'أدمن' : user.role == 'doctor' ? 'صيدلي' : user.role == 'delivery' ? 'طيار' : user.role == 'purchases' ? 'مشتريات' : user.role == 'casher' ? 'كاشير' : ''}</td>
                <td data-label="رقم الهاتف">{user?.phone}</td>
                <td data-label="إجمالي المبلغ">{user?.unpaidAmount}</td>
                <td data-label=" سداد">
                  <i className={` fs-5 ${user?.unpaidAmount === "0.00" ? 'bi bi-bag-check-fill text-success' : 'text-danger bi bi-bag-x-fill'}`} onClick={() => { setUserId(user.id) }} data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                </td>
                <td data-label="خيارات" style={{ minWidth: "110px" }}>
                  <NavLink to={`/users/delete/${user.id}`} >
                    <i className='bi bi-trash text-danger  fs-5'></i>
                  </NavLink>
                  <NavLink to={`/users/edite/${user.id}`} >
                    <i className='bi bi-pencil-square text-primary fs-5 mx-1'></i>
                  </NavLink>
                  <NavLink to={`/users/details/${user.id}`} >
                    <i className='bi bi-list-ul text-success fs-5'></i>
                  </NavLink>
                </td>
              </tr>
              )}
            </tbody>

          </table>
        </div>
      )
    } else {

      return (
        <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
          {users.length <= 0 && searchText.length <= 0 ?
            <i className='fa fa-spinner fa-spin  fa-3x'></i>
            : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق لهذا البحث</div>
          }
        </div>
      )

    }
  };

  let [deliveryPoints,setDeliveryPoints] = useState([]);
  let getDeliveryPoints = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/deliveryPoints`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setDeliveryPoints(data);
  }
useEffect(() => { getDeliveryPoints() }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>
      <div className=" my-3 text-center row mx-2  ">
        <div className="col-md-5">
          <NavLink to='/users/add' className='btn btn-primary ' >إضافة مستخدم</NavLink>
        </div>
        <div className="col-md-4 my-1">
          <input type="text" className='form-control text-end' placeholder=' ...بحث عن مستخدم' onChange={handleSearchChange} />
        </div>
        <div className='col-md-3'>
          <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#deliveryPoints">
            عدد نقاط الطيارين
          </button>
        </div>
      </div>
      {/* paid modal  */}
      <div dir='rtl' className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5" id="exampleModalLabel">  هل أنت متأكد من تسديد المبلغ كاملا؟    </h1>
            </div>
            <div className="modal-body">
              <div className="my-2">
                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                <button type="button" className="btn btn-primary" onClick={() => { makePaidForAllOrders(userId) }}>تأكيد </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* delivery points modal  */}
      <div dir='rtl' className="modal fade" id="deliveryPoints" tabIndex={-1} aria-labelledby="deliveryPointsLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl  ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5  m-auto" id="deliveryPointsLabel">عدد النقاط للطيارين</h1>
            </div>
            <div className="modal-body">
              <div className="rounded rounded-4 bg-white mx-3 p-3 border table-responsive">
                <table dir="rtl" className='table table-bordered  table-hover text-center table-responsive-list '>
                  <thead className='table-primary  no-wrap-heading' >
                    <tr >
                      <th>كود المستخدم</th>
                      <th>الاسم</th>
                      <th> نقاط الشهر قبل السابق</th>
                      <th> نقاط الشهر السابق</th>
                      <th> نقاط الشهر الحالي</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryPoints.map((delivery,index) => (
                        <tr key={index}>
                          <td data-label="كود المستخدم">{delivery?.code}</td>
                          <td data-label="اسم المستخدم">{delivery?.name}</td>
                          <td data-label="نقاط الشهر قبل السابق">{delivery?.monthBeforeLastPoints}</td>
                          <td data-label="نقاط الشهر السابق">{delivery?.lastMonthPoints}</td>
                          <td data-label="نقاط الشهر الحالي">{delivery?.currentMonthPoints}</td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="m-2">
              <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
            </div>
          </div>
        </div>
      </div>
      {showUsers()}
    </>
  )
} 
