

import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../Context/AuthStore';

export default function AddAbuQirClients() {
  let { accessToken } = useContext(AuthContext);
  //selcting form inputs to reset the form after submtion
  const formRef = useRef(null);
  let [isLoading, setIsLoading] = useState(false);
  let [abuQirClients, setAbuQirClients] = useState({
    name: '',
    registrationNumber: '',
    phone: '',
    status_id: '',
    notes: ''
  });

  let getInputValue = (event) => {
    let myClients = { ...abuQirClients }; //deep copy
    myClients[event.target.name] = event.target.value;
    setAbuQirClients(myClients)
  };
  let sendAbuQirClientsDataToApi = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/abuQirClients`, abuQirClients, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      }).then((res) => {
        formRef.current.reset();
        toast.success(res.data.message, {
          position: 'top-center'
        });
        setIsLoading(false);
      }).catch((errors) => {
        setIsLoading(false);
        const errorList = errors?.response?.data?.message;
        if (errorList !== undefined) {
          Object.keys(errorList)?.map((err) => {
            errorList[err]?.map((err) => {
              toast.error(err);
            })
          });
        } else {
          toast.error("حدث خطأ ما");
        }
      });
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  let validateAbuQirClientsForm = () => {
    const schema = Joi.object({
      name: Joi.string().required().messages({
        'string.empty': 'اسم العميل  مطلوب',
        'any.required': 'اسم العميل  مطلوب',
      }),
      registrationNumber: Joi.string().required().messages({
        'string.empty': ' رقم القيد  مطلوب',
        'any.required': ' رقم القيد  مطلوب',
      }),
      phone: Joi.string().pattern(/^01[0125][0-9]{8}$/).message('رقم الهاتف غير صالح').empty(''),
      status_id: Joi.string().required(),
      notes: Joi.string().empty(''),
    });
    return schema.validate(abuQirClients, { abortEarly: false });
  };
  let submitAbuQirClientsForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let validation = validateAbuQirClientsForm();
    if (!validation.error) {
      sendAbuQirClientsDataToApi();
    } else {
      setIsLoading(false);
      try {
        validation?.error?.details?.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");
      }
    }
  };
  //get client status
  let [clientsStatus, setClientsStatus] = useState([]);
  let getClientsStatus = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/AbuQirClientStatus`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setClientsStatus(data)
  }
  useEffect(() => {
    getClientsStatus()
  }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add AbuQirClients</title>
      </Helmet>
      <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة عميل </h3>
      <div className="mx-5 p-3 rounded rounded-3 bg-white">
        <form ref={formRef} onSubmit={submitAbuQirClientsForm} >
          <div className="row gy-3">
            <div className="col-md-4">
              <label htmlFor="name" className='form-label'>اسم العميل </label>
              <input
                type="text"
                className='form-control'
                name="name"
                onChange={getInputValue} />
            </div>
            <div className="col-md-4">
              <label htmlFor="registrationNumber" className='form-label'>رقم القيد</label>
              <input
                type="text"
                className='form-control'
                name="registrationNumber"
                onChange={getInputValue} />
            </div>
            <div className="col-md-4">
              <label htmlFor="phone" className='form-label'> هاتف العميل</label>
              <input
                type="number"
                className='form-control'
                name="phone"
                onChange={getInputValue} />
            </div>
            <div className="col-md-4">
              <label htmlFor="status_id" className='form-label'>حالته </label>
              <select name="status_id" className='form-control ' defaultValue={0} onChange={getInputValue}>
                <option value={0} hidden disabled>اختر</option>
                {clientsStatus.map((status) => <option key={status.id} value={status.id}>{status?.name}</option>)}
              </select>
            </div>
            <div className="col-md-12">
              <label htmlFor="notes" className='form-label'>ملاحظات</label>
              <textarea
                name="notes"
                className='form-control'
                onChange={getInputValue} />
            </div>
            <div className="col-md-3">
              <button type='submit' className='btn btn-primary form-control fs-5'>
                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
              </button>
            </div>

          </div>
        </form >
      </div >
    </>
  )
}
