import React from 'react'
import DeletePurchases from '../../Purchases/DeletePurchases'

export default function DeletePurchasesUser() {
  return (
   <>
   <DeletePurchases/>
   </>
  )
}
