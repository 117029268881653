
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';
import Pagination from '../Pagination/Pagination';
import { useOutletContext } from 'react-router-dom';


export default function TransfersProducts() {
    let { accessToken , setTransfersCount } = useContext(AuthContext);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [transfersProducts, setTransfersProducts] = useState([]);

    let getTransfersProducts = async (page = 1) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/shortcomings/transfersProducts?page=${page}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setTransfersProducts(data.data);
        setPagination(data.meta); // Set pagination data
        setCurrentPage(page); // Set current page  
        setTransfersCount(data.meta.total);
    }

    useEffect(() => {
        getTransfersProducts();
        getStatusData()
    }, []);
    //for handle page change
    let handlePageChange = (page) => {
        getTransfersProducts(page);
    };
    //get status data
    let [statusData, setStatusData] = useState([]);
    let getStatusData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/status`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setStatusData(data)
    }
    let [status, setStatus] = useState({
        status_id: ''
    })
    let sendEditedStatusDataToApi = async (purchId) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/shortcomings/updateStatus/${purchId}`, status, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message)
            getTransfersProducts()

        }).catch((errors) => {
            if (errors.response.data.message == "غير موجود") {
                toast.error(errors.response.data.message)
            } else {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            }
        })
    };
    let [purchId, setPurchId] = useState('')
    useEffect(() => {
        if (status.status_id !== '') {
            sendEditedStatusDataToApi(purchId);
            setStatus({
                status_id: ''
            });
        }
    }, [status.status_id]);

    let showTransfersProducts = () => {
        if (transfersProducts.length > 0) {
            return (
                <>
                    <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                        <table dir="rtl" className='table table-bordered table-hover text-center align-middle table-responsive-list'>
                            <thead className='table-primary no-wrap-heading'>
                                <tr>
                                    <th>اسم الصنف</th>
                                    <th>العميل</th>
                                    <th>الحالة</th>
                                    <th> تغيير الحالة</th>
                                    <th > ملاحظات</th>
                                    <th>الفرع</th>
                                    <th>الموظف</th>
                                    <th>متوفر بالفرع الأخر</th>
                                    <th>نوع المنتج</th>
                                    <th> تاريخ الإنشاء</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transfersProducts.map((purch, index) => <tr key={index}>
                                    <td data-label="اسم الصنف">{purch?.productName}</td>
                                    <td data-label="العميل"  >{purch?.clientInfo}</td>
                                    <td data-label="الحالة" >{purch?.status?.name}</td>
                                    <td data-label="تغيير الحالة"  >
                                        <div >
                                            <select
                                                name="status_id"
                                                className='form-control m-auto'
                                                id="status_id"
                                                defaultValue={0}
                                                onChange={(event) => {
                                                    setStatus({
                                                        ...status,
                                                        status_id: event?.target?.value
                                                    });
                                                    setPurchId(purch.id);
                                                }}
                                            >
                                                <option value={0} hidden disabled>اختر</option>
                                                {statusData
                                                    .filter(stat => stat.name === "غير متوفر بالفرع" || stat.name === "متوفر وتم التحويل")
                                                    .map((stat) => (
                                                        <option key={stat.id} value={stat.id}>{stat?.name}</option>
                                                    ))}
                                            </select>

                                        </div>
                                    </td>
                                    <td data-label="ملاحظات">{purch?.notes == 'undefined' ? '' : purch?.notes}</td>
                                    <td data-label="الفرع">{purch?.branch?.name}</td>
                                    <td data-label="الموظف">{purch?.creatorUser?.name}</td>
                                    <td data-label="متوفر بالفرع الأخر">{purch?.isAvailable_inOtherBranch == 1 ? "متوفر" : "غير متوفر"}</td>
                                    <td data-label="نوع المنتج"  >{purch?.productType}</td>
                                    <td data-label="تاريخ الإنشاء"  >{purch?.created_at}</td>

                                </tr>

                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        } else {
            return (
                <div className='d-flex justify-content-center height-calc-70 align-items-center'>
                    <span className='alert alert-danger w-50 text-center'>لا يوجد تحويلات</span>
                </div>
            );
        }
    }



    return (
        <>
            <div className="text-center my-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showTransfersProducts()}
        </>
    );
}
