

import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Joi from 'joi';


export default function DeliveryPopUpOrders() {
    let { accessToken } = useContext(AuthContext);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [orders, setOrders] = useState([]);
    let getOrderData = async (page = 1) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/unAcceptedOrders?page=${page}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        console.log(data);
        
        setOrders(data.data);
        setPagination(data.meta); // Set pagination data
        setCurrentPage(page); // Set current page

    }
    useEffect(() => { getOrderData() }, []);
    //for handle page change
    let handlePageChange = (page) => {
        getOrderData(page);
    };
    
    let acceptOrder = async (orderId) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/acceptPopUpOrder/${orderId}`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getOrderData()
        }).catch((errors) => {
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });

                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error('حدث خطأ ما')
            }
        });
        
    }

  
    let [orderId, setOrderId] = useState('');
    let showOrders = () => {
        if (orders.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                    <table dir="rtl" className='table  table-hover text-center align-middle '>
                        <thead className='table-primary  no-wrap-heading'>
                            <tr>
                                <th>كود العميل</th>
                                <th> قيمة الأوردر</th>
                                <th>قبول </th>
                                <th> ملاحظات </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => <tr key={order.id}>   
                                <td>{order?.customer?.code}</td>
                                <td >{order?.cost}</td>
                                <td>
                                    <button onClick={() => { setOrderId(order.id) }} type="button" className="btn  btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        قبول
                                    </button>
                                </td>
                                     <td data-label="ملاحظات">{order?.notes}</td>
                            </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                        <div className='alert alert-danger w-50 text-center'>لا يوجد أوردرات</div>
                </div>
            )
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders</title>
            </Helmet>
            <div>
                <p className='alert alert-danger m-3 text-center fw-bold fs-4' >الأوردرات المعلقة</p>
            </div>
            {/* sure modal  */}

            <div dir='rtl' className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5" id="exampleModalLabel">هل أنت متأكد من قبول هذا الأوردر؟</h1>
                        </div>
                        <div className="modal-body">                                
                                <div className="my-2">
                                    <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>{acceptOrder(orderId)}}>تأكيد </button>
                                </div>  
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center my-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showOrders()}
        </>
    )
}
