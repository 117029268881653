import React from 'react'
import AddInventoryProducts from '../../InventoryProducts/AddInventoryProducts'

export default function AddPurchasesInventoryProducts() {
  return (
<>
<AddInventoryProducts/>
</>
  )
}
