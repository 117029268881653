import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';
import Select from 'react-select';

export default function AddClientsInterests({clientId , getClientData , getInterstsData,interestsOptions }) {
  let { accessToken } = useContext(AuthContext);
  
  const clientInterestSelectRef = useRef(null);
  let [interests, setInterests] = useState({
    interests: []
  });
  let getOneClient = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/customers/show/${clientId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setInterests({
        interests : data.data.interests.map(element=> element.id)
    });    
    } catch (error) {        
      toast.error('حدث خطأ ما، حاول مرة أخرى')
    }

  };
  useEffect(() => {
    getInterstsData();
    if (clientId){
        getOneClient()
    }
}, [clientId]);
  let handleInterestsChange = (selectedOption) => {   
    let selectedValues = selectedOption.map((opt) => opt.value)    
    setInterests(prevData => ({
        ...prevData,
        interests: Array.from(new Set([...prevData.interests, ...selectedValues]))
    }));    
};

    //for adding customer interests
    let addingCustomerInterests = async (e) => {
        e.preventDefault();   
      await axios.put(`${process.env.REACT_APP_API_URL}/api/customers/${clientId}`, interests, {
          headers: {
              "Authorization": `Bearer ${accessToken}`
          }
      }).then((res) => {
        clientInterestSelectRef.current.clearValue();
        getClientData()
          toast.success(res.data.message);
      }).catch((errors) => {
        console.log(errors);
        
          toast.error('حدث خطأ ما');
      })
  }
  
  return (
  <>
          <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form  onSubmit={addingCustomerInterests} >
                    <div className="row gy-3">
                    <div className="col-md-12">
                            <label htmlFor="body" className='form-label'>الاهتمامات</label>
                            <Select
                         ref={clientInterestSelectRef}
                            options={interestsOptions}
                            isMulti
                            onChange={handleInterestsChange}
                            isSearchable={true}
                            placeholder="بحث عن اهتمام..."

                        />
                        </div>
             
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control' data-bs-dismiss="modal">
                                إضافة
                            </button>
                        </div>

                    </div>
                </form >
            </div >
  </>
  )
}
