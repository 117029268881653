
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function ClientsInterests() {
    const { accessToken } = useContext(AuthContext);
    const formInput = useRef(null);
    const [clientsInterests, setClientsInterests] = useState({ name: '' });
    const [clientsInterestsData, setClientsInterestsData] = useState([]);
    const [interestsId, setInterestsId] = useState('');
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        getClientsInterestsData();
    }, []);

    const getClientsInterestsData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/customersInterests`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });            
            setClientsInterestsData(data);
        } catch (error) {
            toast.error('حدث خطأ أثناء جلب البيانات');
        }
    };

    const getInputValue = (event) => {
        const { name, value } = event.target;
        setClientsInterests(prevState => ({ ...prevState, [name]: value }));
    };

    const submitInterestsOrEditedInterests = async (event) => {
        event.preventDefault();
        if (!clientsInterests.name.trim()) {
            toast.error("اهتمام العميل مطلوب");
            return;
        }
        try {
            if (editing) {
            let {data} =     await axios.put(`${process.env.REACT_APP_API_URL}/api/properties/${interestsId}`, clientsInterests, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                toast.success(data.message);
            } else {
                let {data} =     await axios.post(`${process.env.REACT_APP_API_URL}/api/properties/customersInterests `, clientsInterests, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                toast.success(data.message);
            }
            setEditing(false);
            setClientsInterests({ name: '' });
            formInput.current.reset();
            getClientsInterestsData();
        } catch (error) {
            toast.error('حدث خطأ أثناء العملية');
        }
    };

    const handleEdit = (index) => {        
        const interests = clientsInterestsData[index];
        setClientsInterests({ name: interests?.name });
        setInterestsId(interests.id);
        setEditing(true);
    };

    const deleteInterests = async (interestId) => {
        try {
         let {data} =    await axios.delete(`${process.env.REACT_APP_API_URL}/api/properties/delete/${interestId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getClientsInterestsData();
        } catch (error) {
            toast.error('حدث خطأ أثناء العملية');
        }
    };

    return (
        <>
            <div className="container-fluid my-2">
                <div className='row gx-1'>
                    <div className="col-md-5">
                        <h4 className='alert alert-primary text-center'>إضافة اهتمام</h4>
                        <form ref={formInput} onSubmit={submitInterestsOrEditedInterests}>
                            <div className='w-75 m-auto'>
                                <label htmlFor="statusName" className='form-label'>الاهتمام </label>
                                <input type="text" name="name" id="statusName" className='form-control' value={clientsInterests.name} onChange={getInputValue} />
                                <button type='submit' className={`btn ${editing ? 'btn-outline-danger' : 'btn-outline-primary'} mt-3`}>
                                    {editing ? 'تعديل' : 'إضافة'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-7 card p-2 mt-5">
                        <h4 className='text-center text-bg-secondary py-2 rounded'>اهتمامات العملاء</h4>
                        {clientsInterestsData.length > 0 ? (
                            <div className='w-100'>
                                {clientsInterestsData.map((interest, index) => (
                                    <div key={interest.id} className="row">
                                        <div className="col-md-8 d-flex">
                                            <p className='bg-body-secondary p-1 rounded'>{interest.name}</p>
                                        </div>
                                        <div className="col-md-4 d-flex">
                                            <p>
                                            <button className='btn btn-outline-danger btn-sm ms-3' onClick={() => deleteInterests(interest.id)}><i className='bi bi-trash'></i> حذف </button>
                                            </p>
                                            <p>
                                            <button className='btn btn-outline-primary btn-sm' onClick={() => handleEdit(index)}><i className='bi bi-pencil-square'></i> تعديل</button>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='h-75'>
                                <div className=' d-flex justify-content-center h-100 align-items-center'>
                                    <i className='fa fa-spinner fa-spin fa-2x'></i>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
