import React from 'react'
import CasherNavbar from '../CasherNavbar/CasherNavbar'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import SalePointModal from '../SalePointModal/SalePointModal'

export default function CasherLayout() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Casher</title>
            </Helmet>
            <SalePointModal/>
            <CasherNavbar />
            <Outlet />
        </>
    )
}
