import React from 'react'
import PurchasesDeatils from '../../Purchases/PurchasesDeatils'

export default function PurchasesUserDetails() {
  return (
   <>
   <PurchasesDeatils/>
   </>
  )
}
