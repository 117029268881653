import React from 'react'
import EditeCompany from '../../Companies/EditeCompany'

export default function EditePurchasesCompanies() {
  return (
  <>
  <EditeCompany/>
  </>
  )
}
