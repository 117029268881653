import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AddClientsInterests from './AddClientsInterests';

export default function Clients() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [clients, setClients] = useState([]);
    let [clientId, setClientId] = useState('');
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [searchText, setSearchText] = useState('');
    function handleSearchChange(event) {
        setSearchText(event.target.value);
    };
    let [filterAreaId, setFilterAreaId] = useState('');
    function handleAreaChange(selectedOption) {
        setFilterAreaId(selectedOption.value)
    }
    let [filterInterestId, setFilterInterestId] = useState('');
    function handleInterestChange(selectedOption) {
        setFilterInterestId(selectedOption.value)
    }
    let [interestsData, setInterestsData] = useState([]);
    let [interestsOptions, setInterestsOptions] = useState([]);
    //getting intersts data to display in select 
    let getInterestsData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/customersInterests`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setInterestsData(data);
        } catch (error) {
            toast.error('حدث خطأ أثناء جلب البيانات');
        }
    };
    useEffect(() => {
        let mapInterests = interestsData?.map((interest) => ({
            value: `${interest.id}`,
            label: `${interest.name}`
        }));
        setInterestsOptions(mapInterests);
    }, [interestsData]);
    //getting areas data to display in select 
    let [areasData, setAreasData] = useState([]);
    let getAreasData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/areas`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setAreasData(data);
        } catch (error) {
            toast.error('حدث خطأ أثناء جلب البيانات');
        }
    }
    useEffect(() => {
        getAreasData();
        getInterestsData()
    }, []);
    //making map on the areas data to display the name in the option
    let [areasOptions, setAreasOptions] = useState([])
    useEffect(() => {
        let mapAreas = areasData?.map((area) => ({
            value: `${area.id}`,
            label: `${area.name}`
        }));
        setAreasOptions(mapAreas);
    }, [areasData]);

    let getClientData = async (page = 1) => {
        let clientResult;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/customers/filter?`;
        if (filterAreaId !== undefined && filterAreaId.length > 0) {
            urlApi += `area_id=${filterAreaId}&`
        }
        if (filterInterestId !== undefined && filterInterestId.length > 0) {
            urlApi += `interest_id=${filterInterestId}&`
        }
        if (searchText !== undefined && searchText.trim().length > 0) {
            urlApi += `key=${searchText}&`
        }
        urlApi += `page=${page}`
        clientResult = await axios.get(urlApi, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        if (clientResult) {
            setClients(clientResult.data.data);
            setPagination(clientResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        }
    };
    useEffect(() => {
        getClientData()
    }, [searchText, filterAreaId,filterInterestId])
    //for handle page change
    let handlePageChange = (page) => {
        getClientData(page);
    };

    //for making checkBox for every one
    let sendUpdateCheckBoxToApi = async (custId) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/customers/updateCheckBox/${custId}`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getClientData()
        }).catch((errors) => {
            toast.error(errors?.response?.data?.message);
        })
    }
    //for making reset all checkBox
    let sendResetCheckBoxToApi = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/customers/updateCheckBox/all`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getClientData()
        }).catch((errors) => {
            toast.error(errors?.response?.data?.message);
        })
    }

    //for making checkBox for Client follow-up
    let sendUpdateCustomerCheckBoxToApi = async (custId) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/customers/updatecustomerCheck/${custId}`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getClientData()
        }).catch((errors) => {
            toast.error(errors?.response?.data?.message);
        })
    }
    //for making reset all customer checkBox
    let sendResetCustomerCheckBoxToApi = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/customers/updatecustomerCheck/all`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getClientData()
        }).catch((errors) => {
            toast.error(errors?.response?.data?.message);
        })
    }

    let showClients = () => {
        if (clients.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <div style={{ cursor: 'pointer' }} onClick={sendResetCheckBoxToApi} className='bg-danger text-white w-75 text-center m-auto d-block d-sm-none p-1 rounded'>عدم تحديد الكل</div>
                    <div style={{ cursor: 'pointer' }} onClick={sendResetCustomerCheckBoxToApi} className='bg-primary text-white w-75 text-center m-auto d-block d-sm-none p-1 mt-1 rounded'>متابعة العميل</div>

                    <table dir="rtl" className='table  table-hover align-middle text-center table-responsive-list  '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th>رقم</th>
                                <th>كود العميل</th>
                                <th>الاسم</th>
                                <th>المنطقة الإفتراضية</th>
                                <th>المناطق الأخرى </th>
                                <th>أرقام الهواتف</th>
                                <th>الاهتمامات</th>
                                <th> تيكت </th>
                                {/* <th>له</th>
                                <th>عليه</th> */}
                                <th>إضافة اهتمامات</th>
                                {currentPath === '/clients' && (
                                    <>
                                        <th>خيارات</th>
                                    </>

                                )}

                                <th>
                                    <div style={{ cursor: 'pointer' }} onClick={sendResetCustomerCheckBoxToApi} className='bg-primary text-white p-1 rounded'>متابعة العميل</div>

                                </th>
                                <th>
                                    <div style={{ cursor: 'pointer' }} onClick={sendResetCheckBoxToApi} className='bg-danger text-white p-1 rounded'>عدم تحديد الكل</div>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client, index) => <tr key={client.id}>
                                <td data-label="#">{++index}</td>
                                <td data-label="كود العميل">{client?.code}</td>
                                <td data-label="اسم العميل">{client?.name}</td>
                                <td data-label=" المنطقة الإفتراضية">{client?.defaultArea?.name}</td>
                                <td data-label="المناطق الأخرى">
                                    {client?.areas?.map((area) => (<div key={area.id}>

                                        <p >{area.name}</p>
                                    </div>
                                    )
                                    )}
                                </td>
                                <td data-label="أرقام الهواتف">
                                    {client?.contactInfo?.map((contactInfo) => {
                                        if (contactInfo.name === "phone") {
                                            return <p key={contactInfo.id}>{contactInfo.value}</p>
                                        }
                                    })}
                                </td>
                                <td data-label="المناطق الأخرى">
                                    {client?.interests?.map((area) => (<div key={area.id}>

                                        <p >{area.name}</p>
                                    </div>
                                    )
                                    )}
                                </td>
                                <td data-label="تيكت">{client?.customer_ticket}</td>
                                <td>
                                    <i className="bi bi-file-plus-fill text-secondary fs-5" data-bs-toggle="modal" data-bs-target="#clientsInterests" onClick={() => setClientId(client.id)} ></i>
                                </td>
                                {/* <td data-label="له">{client?.forHim}</td>
                                <td data-label="عليه">{client?.onHim}</td> */}
                                {currentPath === '/clients' && (
                                    <>

                                        <td data-label="خيارات" >
                                            <NavLink to={`/clients/delete/${client.id}`}>
                                                <i className='bi bi-trash text-danger fs-5 p-1'></i>
                                            </NavLink>
                                            <NavLink to={`/clients/edite/${client.id}`}>
                                                <i className='bi bi-pencil-square text-primary p-1 fs-5'></i>
                                            </NavLink>
                                            <NavLink to={`/clients/details/${client.id}`}>
                                                <i className='bi bi-list-ul text-success fs-5 p-1'></i>
                                            </NavLink>
                                        </td>
                                    </>

                                )}

                                <td >
                                    {client.customer_check ? <i className='bi bi-check-circle-fill  text-primary fs-5'
                                        onClick={() => sendUpdateCustomerCheckBoxToApi(client.id)} ></i>
                                        : <i className='bi bi-x-circle-fill text-danger fs-5 '
                                            onClick={() => sendUpdateCustomerCheckBoxToApi(client.id)} ></i>}
                                </td>
                                <td >
                                    {client.checkBox ? <i className='bi bi-check-circle-fill text-success fs-5'
                                        onClick={() => sendUpdateCheckBoxToApi(client.id)} ></i>
                                        : <i className='bi bi-x-circle-fill text-danger fs-5 '
                                            onClick={() => sendUpdateCheckBoxToApi(client.id)} ></i>}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {clients.length <= 0 && searchText.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-2x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق لهذا البحث</div>
                    }
                </div>
            )
        }
    };

    return (

        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Clients</title>
            </Helmet>
            <div className=" my-3 text-center row gy-1 mx-2" dir='rtl'>
                <div className="col-md-3">
                    <NavLink to='/clients/add' className='btn btn-primary'>إضافة عميل</NavLink>
                </div>
                <div className="col-md-3">
                    <input type="text" className='form-control text-end' onChange={handleSearchChange} placeholder='بحث عن عميل...' />
                </div>
                <div className='col-md-3'>
                    <Select
                        options={areasOptions}
                        onChange={handleAreaChange}
                        isSearchable={true}
                        placeholder="فلتر المنطقة..."

                    />
                </div>
                <div className='col-md-3'>
                    <Select
                        options={interestsOptions}
                        onChange={handleInterestChange}
                        isSearchable={true}
                        placeholder="فلتر الاهتمام..."
                    />
                </div>
            </div>

            {/* clients Interests */}
            <div dir='rtl' className="modal fade" id="clientsInterests" tabIndex={-1}
                aria-labelledby="clientsInterests" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="clientsInterestsLabel">
                                إضافة اهتمامات العملاء
                            </h1>
                        </div>
                        <div className="modal-body ">
                            <AddClientsInterests 
                            clientId={clientId}
                             getClientData={getClientData} 
                             getInterstsData={getInterestsData} 
                              interestsOptions={interestsOptions}/>
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showClients()}
        </>)
}
