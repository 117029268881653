import React, { useContext, useEffect, useState } from 'react';
import {  NavLink } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { AuthContext } from '../../Context/AuthStore';


export default function Sidebar() {

    
    let { userData , transfersCount } = useContext(AuthContext);

    let [clientSubmenuOpen, setClientSubmenuOpen] = useState(false);
    let toggleClientSubmenu = () => {
        setClientSubmenuOpen(!clientSubmenuOpen);
    }
    let [managementSubmenuOpen, setManagementSubmenuOpen] = useState(false);
    let toggleManagementSubmenu = () => {
        setManagementSubmenuOpen(!managementSubmenuOpen);
    }
    let [sellingIncentivesSubmenuOpen, setSellingIncentivesSubmenuOpen] = useState(false);
    let toggleSellingIncentivesSubmenu = () => {
        setSellingIncentivesSubmenuOpen(!sellingIncentivesSubmenuOpen);
    }
    let [abuQirSubmenuOpen, setAbuQirSubmenuOpen] = useState(false);
    let toggleAbuQirSubmenu = () => {
        setAbuQirSubmenuOpen(!abuQirSubmenuOpen);
    }

    return (
        <>
            {userData ?
                <aside className={`container-fluid position-fixed top-0 bottom-0`}>
                    <div className="row overflow-auto vh-100  ">
                        <div className={`bg-white   col-auto col-md-2  d-flex flex-column `} >
                            <div >
                                <div className={`${styles.height_55}  d-flex  align-items-center`}>
                                    <button className='nav-link '  >
                                        <i className="bi bi-chevron-left ms-3"></i>
                                    </button>

                                </div>
                                <div className='border-1 border-bottom border-secondary-50'></div>

                                <div>
                                    <ul className="nav  flex-column mb-2 ">
                                        <li className="nav-item ">
                                            <NavLink to='/home' className="nav-link fw-bolder" >
                                                <span className='me-3 d-none d-sm-inline'> الرئيسية </span>
                                                <i className="bi bi-house-fill "></i>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to='orders' className="nav-link  fw-bolder" >
                                                <span className='me-3 d-none d-sm-inline'> الأوردرات </span>
                                                <i className="bi bi-cart-check-fill "></i>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item ">
                                            <NavLink to='inventoryproducts' className="nav-link  fw-bolder" >
                                                <span className='me-3 d-none d-sm-inline'> الجرد </span>
                                                <i className="bi bi-receipt-cutoff"></i>                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <div onClick={toggleClientSubmenu} className="nav-link fw-bolder">
                                                <span className='me-3 d-none d-sm-inline'>العملاء</span>
                                                <i className="bi bi-people-fill "></i>
                                            </div>

                                            {clientSubmenuOpen &&
                                                <div>
                                                    <NavLink to='clients' className="nav-link me-4 fw-bolder" >
                                                        <span className='me-2 d-none d-sm-inline'>بيانات العملاء</span>
                                                        <i className="bi bi-people-fill "></i>
                                                    </NavLink>

                                                    <NavLink to='customersservice' className="nav-link me-4 fw-bolder" >
                                                        <span className='me-2 d-none d-sm-inline '>خدمة العملاء </span>
                                                        <i className="bi bi-telephone-outbound-fill "></i>
                                                    </NavLink>
                                                    <NavLink to='clientsinterests' className="nav-link me-4 fw-bolder" >
                                                        <span className='me-2 d-none d-sm-inline '>اهتمام العملاء</span>
                                                        <i className="bi bi-clipboard-heart-fill"></i>
                                                    </NavLink>
                                                    <NavLink to='/areas' className="nav-link me-4 fw-bolder" >
                                                        <span className='me-2 d-none d-sm-inline '>المناطق</span>
                                                        <i className="bi bi-geo-fill"></i>
                                                    </NavLink>
                                                </div>
                                            }

                                        </li>


                                        <li className="nav-item">
                                            <NavLink to='/transfersproducts' className="nav-link fw-bolder " >
                                                <span className={`${transfersCount > 0 ?'':'me-3'}  d-none d-sm-inline`}> التحويلات </span>
                                                {transfersCount > 0 && <span className="badge bg-danger me-2"> {transfersCount} </span>}
                                                <i className="bi bi-arrow-left-right"></i>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to='/offers' className="nav-link fw-bolder " >
                                                <span className='me-3 d-none d-sm-inline'> العروض </span>
                                                <i className="bi bi-basket2"></i>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item ">
                                            <NavLink to='companies' className="nav-link  fw-bolder" >
                                                <span className='me-3 d-none d-sm-inline'>الشركات </span>
                                                <i className="bi bi-border-style"></i>
                                            </NavLink>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                            <div className='border-1 border-bottom border-secondary-50 '></div>

                            <div>
                                <ul className="nav flex-column mt-2">
                                    <li className="nav-item ">
                                        <div onClick={toggleAbuQirSubmenu} className="nav-link fw-bolder">
                                            <span className='me-3 d-none d-sm-inline'>  أبو قير</span>
                                            <i className="bi bi-building-fill-add"></i>
                                        </div>
                                        {abuQirSubmenuOpen &&
                                            <div>
                                                <NavLink to='/abuqirclients' className="nav-link me-4 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline'>العملاء</span>
                                                    <i className="bi bi-people-fill "></i>
                                                </NavLink>

                                                <NavLink to='/abuqirforms' className="nav-link me-4 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline '>الإستمارات</span>
                                                    <i className="bi bi-card-heading"></i>
                                                </NavLink>
                                                <NavLink to='/abuqir-bills' className="nav-link me-4 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline '>لنا فواتير</span>
                                                    <i className="bi bi-cash-stack"></i>
                                                </NavLink>
                                            </div>
                                        }

                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/notifications' className="nav-link  fw-bolder" >
                                            <span className='me-3 d-none d-sm-inline '>الإشعارات</span>
                                            <i className="bi bi-bell-fill"></i>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item ">
                                        <div onClick={toggleSellingIncentivesSubmenu} className="nav-link fw-bolder">
                                            <span className='me-3 d-none d-sm-inline'>حوافز البيع</span>
                                            <i className="bi bi-cash-coin "></i>
                                        </div>
                                        {sellingIncentivesSubmenuOpen &&
                                            <div>
                                                <NavLink to='/reasonsofincentives' className="nav-link me-4 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline'>الأسباب</span>
                                                    <i className="bi bi-card-list"></i>
                                                </NavLink>

                                                <NavLink to='/sellingincentives' className="nav-link me-4 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline '>المنتجات</span>
                                                    <i className="bi bi-capsule-pill"></i>
                                                </NavLink>
                                            </div>
                                        }

                                    </li>


                                    <li className="nav-item">
                                        <NavLink to='/purchases' className="nav-link  fw-bolder">
                                            <span className='me-3 d-none d-sm-inline'> المشتريات</span>
                                            <i className="bi bi-bag-fill"></i>

                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/doctors' className="nav-link  fw-bolder">
                                            <span className='me-3 d-none d-sm-inline '>أطباء وتمريض</span>
                                            <i className="bi bi-bag-plus-fill"></i>
                                        </NavLink>
                                    </li>

                                    <li className="nav-item ">
                                        <div onClick={toggleManagementSubmenu} className="nav-link fw-bolder">
                                            <span className='me-3 d-none d-sm-inline'>الإدارة</span>
                                            <i className="bi bi-gear-fill"></i>
                                        </div>
                                        {managementSubmenuOpen &&
                                            <div >
                                                <NavLink to='/employees' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline'>الموظفون</span>
                                                    <i className="bi bi-person-plus-fill"></i>
                                                </NavLink>
                                                <NavLink to='/attendancedeparture' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline '>حضور وانصراف</span>
                                                    <i className="bi bi-clock-history"></i>
                                                </NavLink>
                                                <NavLink to='/monthlyaccounts' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline '> حسابات شهرية </span>
                                                    <i className="bi bi-calculator"></i>
                                                </NavLink>
                                                <NavLink to='/reports' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline '>تقارير</span>
                                                    <i className="bi bi-file-pdf-fill"></i>
                                                </NavLink>
                                                <NavLink to='/workpolicies' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline'> سياسية العمل</span>
                                                    <i className="bi bi-clipboard-data-fill"></i>
                                                </NavLink>
                                                <NavLink to='/rules' className="nav-link me-2 fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline '>قواعد العمل</span>
                                                    <i className="bi bi-book-fill "></i>
                                                </NavLink>
                                                <NavLink to='users' className="nav-link me-2 fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline'> المستخدمون</span>
                                                    <i className="bi bi-file-earmark-person-fill"></i>

                                                </NavLink>

                                                <NavLink to='/branches' className="nav-link me-2  fw-bolder">
                                                    <span className='me-2 d-none d-sm-inline'> الفروع</span>
                                                    <i className="bi bi-geo-alt-fill"></i>
                                                </NavLink>

                                                <NavLink to='salepoints' className="nav-link me-2  fw-bolder" >
                                                    <span className='me-2 d-none d-sm-inline'>نقاط البيع</span>
                                                    <i className="bi bi-person-fill-add"></i>
                                                </NavLink>
                                            </div>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </aside> : ''}

        </>
    )
}
