import React from 'react'
import PopUpOrders from '../Orders/PopUpOrders'

export default function DoctorPopUpOrders() {
    return (
        <>
            <PopUpOrders />
        </>
    )
}
