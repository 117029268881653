// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Orders_modal__5oFmy{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.Orders_modal_content__I6aID{
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.Orders_close__umi07{
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
   
}
`, "",{"version":3,"sources":["webpack://./src/Components/Orders/Orders.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,eAAe;;AAEnB","sourcesContent":[".modal{\n    z-index: 9999;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n.modal_content{\n    position: absolute;\n    top: 50%;\n    left: 45%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 15px;\n    border-radius: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n.close{\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    cursor: pointer;\n   \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Orders_modal__5oFmy`,
	"modal_content": `Orders_modal_content__I6aID`,
	"close": `Orders_close__umi07`
};
export default ___CSS_LOADER_EXPORT___;
