
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthStore';
import Pagination from '../../Pagination/Pagination';
import { toast } from 'react-toastify';
import Select from 'react-select';
import EditeAbuQirForm from './EditeAbuQirForm';



export default function AbuQirForms() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [forms, setForms] = useState([]);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [searchText, setSearchText] = useState('');
    function handleSearchChange(event) {
        setSearchText(event.target.value);
    };
    let [filterClientCode, setFilterClientCode] = useState('');
    function handleClientCodeChange(selectedOption) {
        setFilterClientCode(selectedOption.value)
    }
    let [filterFromDate, setFilterFromDate] = useState('');
    function handleFromDateChange(event) {
        setFilterFromDate(event.target.value)
    }
    let [filterEndDate, setFilterEndDate] = useState('');
    function handleEndDateChange(event) {
        setFilterEndDate(event.target.value)
    }
    let getFormsData = async (page = 1) => {
        let formsResult;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/abuQirForms/filter?`;
        if (filterFromDate !== undefined && filterFromDate.length > 0) {
            urlApi += `fromDate=${filterFromDate}&`
        }
        if (filterEndDate !== undefined && filterEndDate.length > 0) {
            urlApi += `endDate=${filterEndDate}&`
        }
        if (filterClientCode !== undefined && filterClientCode.length > 0) {
            urlApi += `clientCode=${filterClientCode}&`
        }
        if (searchText !== undefined && searchText.trim().length > 0) {
            urlApi += `key=${searchText}&`
        }
        urlApi += `page=${page}`
        try {
            formsResult = await axios.get(urlApi, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
        } catch (error) {
            console.log(error);
            
        }
       
    
        if (formsResult) {
            setForms(formsResult.data.data);
            setPagination(formsResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        }
    };
    useEffect(() => { getFormsData() },
        [filterFromDate, filterClientCode, filterEndDate, searchText]);
    //for handle page change
    let handlePageChange = (page) => {
        getFormsData(page);
    };
    let [clients, setClients] = useState([]);
    let [clientOptions, setClientOptions] = useState([]);
    let getClientData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirClients?noPaginate=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setClients(data.data);
        } catch (error) {
            toast.error('حدث خطأ ما.')
        }
    };

    useEffect(() => {
        getClientData()
    }, []);
    useEffect(() => {
        let mapClient = clients?.map((client) => ({
            value: `${client.registrationNumber}`,
            label: `${client.registrationNumber}`,
        }));
        setClientOptions(mapClient);
    }, [clients]);
    let deleteForm = async (formId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/abuQirForms/delete/${formId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getFormsData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    // for making Claim for specific form
    let makeAclaimForSpecificForm = async (formId) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/abuQirForms/makeAClaim/${formId}`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getFormsData()
        }).catch((errors) => {
            toast.error('حدث خطأ ما');
            toast.error(errors?.response?.data?.message);
        })

    }
    //for making Claim for all form
    let makeAclaimForAllForms = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/abuQirForms/makeAClaim/all`, {}, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getFormsData()
        }).catch((errors) => {
            toast.error('حدث خطأ ما');
            toast.error(errors?.response?.data?.message);
        })
    }

    let [formId, setFormId] = useState('');
    let showForms = () => {
        if (forms.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <div style={{ cursor: 'pointer' }} onClick={makeAclaimForAllForms} className='bg-danger text-white w-75 text-center m-auto d-block d-sm-none p-1 rounded'>  مطالبة بالكل</div>

                    <table dir="rtl" className='table table-bordered table-hover align-middle text-center table-responsive-list '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                {/* <th>م</th> */}
                                <th>تاريخ الإنشاء</th>
                                <th> رقم القيد</th>
                                <th>اسم العميل</th>
                                <th>مساهمة</th>
                                <th> الباقي</th>
                                <th> قيم الإستمارة</th>

                                <th>

                                    <div style={{ cursor: 'pointer' }} onClick={makeAclaimForAllForms} className='bg-danger text-white  rounded'>مطالبة بالكل</div>
                                </th>

                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form) => <tr key={form.id}>
                                {/* <td data-label="م">{form.id}</td> */}
                                <td data-label="تاريخ الإنشاء"  >{form.created_at}</td>
                                <td data-label="رقم القيد">{form?.client?.registrationNumber}</td>
                                <td data-label="اسم العميل ">{form?.client?.name}</td>
                                <td data-label=" مساهمة ">{form.isAContribution === 1 ? 'نعم' : 'لا'}</td>
                                <td data-label="الباقي">{form?.remaining}</td>

                                <td data-label="قيم الإستمارة">
                                    {form?.elements?.map((ele, index) => (
                                        <span key={ele.id}>
                                            <span>{ele.price}</span>
                                            {index !== form.elements.length - 1 && <span> ,</span>} {/* إضافة الفاصلة إلا إذا كانت هذه آخر عنصر */}
                                        </span>
                                    ))}
                                </td>
                                <td data-label="مطالبة">
                                    {form.isClaimed ? <i className='bi bi-check-circle-fill text-success fs-5'
                                        onClick={() => makeAclaimForSpecificForm(form.id)} ></i>
                                        : <i className='bi bi-x-circle-fill text-danger fs-5 '
                                            onClick={() => makeAclaimForSpecificForm(form.id)} ></i>}
                                </td>
                                <td data-label="خيارات" >
                                    <div className="dropdown">
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setFormId(form.id)
                                        }} data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                                        <span data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bi bi-trash text-danger fs-5 '  ></i>
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => { deleteForm(form.id) }}>حذف</button>
                                            </li>
                                        </ul>

                                    </div>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {forms.length <= 0 && searchText.length <= 0 && filterClientCode.length <= 0 && filterEndDate.length <= 0 && filterFromDate.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-2x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق لهذا البحث.</div>
                    }
                </div>
            )
        }
    };

   

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AbuQir Forms</title>
            </Helmet>
            <div className=" my-3 text-center row mx-2 " dir='rtl'>
                <div className="col-md-3">
                    <input type="text" className='form-control text-end mt-4' onChange={handleSearchChange} placeholder='بحث عن المسلسل...' />
                </div>
                <div className='col-md-2' >
                    <label htmlFor="start_date">من يوم</label>
                    <input type="date" className='form-control mb-1' onChange={handleFromDateChange} />
                </div>
                <div className='col-md-2'>
                    <label htmlFor="end_date"> إلى يوم</label>
                    <input type="date" className='form-control mb-1' onChange={handleEndDateChange} />
                </div>
                <div className='col-md-3 mt-4'>
                    <Select
                        options={clientOptions}
                        onChange={handleClientCodeChange}
                        isSearchable={true}
                        placeholder="  بحث رقم القيد"

                    />
                </div>
                <div className="col-md-6">
                    {/* Render NavLink based on the current URL path */}
                    {currentPath === '/abuqirforms' && (
                        <NavLink to='/abuqirforms/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
                    )}
                    {currentPath === '/doctorabuqirforms' && (
                        <NavLink to='/doctorabuqirforms/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
                    )}
                    {currentPath === '/casherabuqirforms' && (
                        <NavLink to='/casherabuqirforms/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
                    )}
                    {currentPath === '/purchasesabuqirforms' && (
                        <NavLink to='/purchasesabuqirforms/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
                    )}


                </div>
                <div className="col-md-6">
                    {currentPath === '/abuqirforms' && (
                        <NavLink to='/claimDetails' className='btn btn-secondary '>الإجمالي </NavLink>
                    )}
                    {currentPath === '/doctorabuqirforms' && (
                        <NavLink to='/doctorclaimDetails' className='btn btn-secondary '>الإجمالي </NavLink>
                    )}
                    {currentPath === '/casherabuqirforms' && (
                        <NavLink to='/casherclaimDetails' className='btn btn-secondary '>الإجمالي </NavLink>
                    )}
                    {currentPath === '/purchasesabuqirforms' && (
                        <NavLink to='/purchasesclaimDetails' className='btn btn-secondary '>الإجمالي </NavLink>
                    )}
                </div>
            </div>



            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showForms()}
            {/* edite form modal */}
            <div dir='rtl' className="modal fade " id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="exampleModalLabel"> تعديل الإستمارة</h1>
                        </div>
                        <div className="modal-body">
                         <EditeAbuQirForm formId = {formId} getFormsData={getFormsData} clients = {clients}/>
                            <div className="my-2">
                                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
