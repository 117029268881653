import React from 'react'
import AddPopUpOrders from '../Orders/AddPopUpOrders'

export default function AddPopUpOrdersDoctor() {
  return (
<>
<AddPopUpOrders/>
</>
  )
}
