import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function SimilarOrders() {
    let { accessToken } = useContext(AuthContext);
    let [similarOrders, setSimilarOrders] = useState([]);
    let [selectedOrders, setSelectedOrders] = useState([]);

    let getSimilarOrders = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/similarOrders`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setSimilarOrders(data);
        } catch (error) {
            toast.error('حدث خطأ أثناء جلب البيانات');
        }
    }

    useEffect(() => {
        getSimilarOrders();
    }, []);
    let showOrderDetails = (orders) => {
        setSelectedOrders(orders);
    }

    let showSimilarOrders = () => {
        if (similarOrders.length > 0) {
            return (
                <div className="row m-4" dir='rtl'>
                    {similarOrders.map((orderData, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="card mb-4 shadow-sm">
                                <div className="card-body">
                                    <h5 className="card-title">كود العميل: {orderData?.customer?.code}</h5>
                                    <p className="card-text">اسم العميل: {orderData?.customer?.name}</p>
                                    <p className="card-text">قيمة الأوردر: {orderData?.group_cost}</p>
                                    <p className="card-text">عدد الأوردرات المكررة: {orderData?.count}</p>
                                    <button
                                        className='btn btn-success btn-sm'
                                        data-bs-toggle="modal"
                                        data-bs-target="#ordersDetails"
                                        onClick={() => showOrderDetails(orderData.orders)}
                                    >
                                        تفاصيل الأوردرات
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center height-calc-70 align-items-center'>
                    <i className='fa fa-spinner fa-spin fa-2x'></i>
                </div>
            );
        }
    }

    let renderOrderDetails = () => {
        return (
            <div className="rounded rounded-4 bg-white  p-3 table-responsive">
            <table dir="rtl" className='table  table-hover text-center align-middle table-responsive-list '>
              <thead className='table-primary  no-wrap-heading'>
                <tr>
                  <th> تاريخ الإنشاء</th>
                  <th>مُنشئ الأوردر</th>
                  <th>طيار الأوردر</th>
                  <th>قيمة الأوردر</th>
      
                </tr>
              </thead>
              <tbody>
                {selectedOrders.map((order) => (
                  <tr key={order.id}>
                    <td data-label="تاريخ الإنشاء"  >{order?.created_at}</td>
                    <td data-label="مُنشئ الأوردر"  >{order?.creator_user?.name}</td>
                    <td data-label="طيار الأوردر"  >{order?.assigned_user?.name}</td>
                    <td data-label="قسمة الأوردر"  >{order?.cost}</td>

                  </tr>
  
                ))}
              </tbody>
  
            </table>
          </div>
        );
    }


    return (
        <>
            {showSimilarOrders()}
            {/* orders details modal */}
            <div dir='rtl' className="modal fade " id="ordersDetails" tabIndex={-1} aria-labelledby="ordersDetailsLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="ordersDetailsLabel"> تفاصيل الأوردرات </h1>
                        </div>
                        <div className="modal-body ">
                            {selectedOrders && renderOrderDetails()}
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
