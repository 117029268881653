
import axios from 'axios';
import Joi, { number } from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Field, Form, Formik, useFormikContext } from "formik";
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import Select from 'react-select';

export default function AddEmployee() {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    const generateTimes = () => {
        const daysOfWeek = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
        return daysOfWeek.map(day => ({
            day,
            attendance_time: '',
            departure_time: ''
        }));
    };
    let [employees, setEmployess] = useState({
        name: '',
        code: '',
        hourRate: '',
        holiday: '',
        times: generateTimes(),
        category_id: '',
        notes: '',
        branch_id: '',
        constantIncentive: ''
    });
    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }
    //get employeeCategory data
    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }
    useEffect(() => {
        getBranches()
        getEmployeeCategory()
    }, []);
    let sendEmployeesDataToApi = async (values) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/employees `, values, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            setIsLoading(false);
            setEmployess({
                name: '',
                code: '',
                hourRate: '',
                holiday: '',
                times: generateTimes(),
                category_id: '',
                notes: '',
                branch_id: '',
                constantIncentive: ''
            })
        }).catch((errors) => {
            setIsLoading(false);
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList).map((err) => {
                        errorList[err].map((err) => {
                            toast.error(err);
                        })
                    });
                }
            } catch (error) {
                toast.error('حدث خطأ ما')
            }
        });
    };
    let validateEmployeesForm = (values) => {
        const schema = Joi.object({
            name: Joi.string().required(),
            code: Joi.string().required(),
            hourRate: Joi.number().required(),
            holiday: Joi.string().empty(''),
            times: Joi.array().items(
                Joi.object({
                    day: Joi.string().required(),
                    attendance_time: Joi.string().empty(''),
                    departure_time: Joi.string().empty('')
                })
            ).required(),
            category_id: Joi.number().required(),
            notes: Joi.any().empty(''),
            branch_id: Joi.number().required(),
            constantIncentive: Joi.string().empty(''),
        });
        return schema.validate(values, { abortEarly: false });
    };
    let submitEmployeesForm = (values, { resetForm }) => {
        setIsLoading(true);
        // Filter out items where either attendance_time or departure_time is empty
        const filteredTimes = values.times.filter(time => {
            return time.day && time.attendance_time && time.departure_time;
        });
        // Update the times array in the values object with the filtered times
        values.times = filteredTimes;
        let validation = validateEmployeesForm(values);
        if (!validation.error) {
            sendEmployeesDataToApi(values);
            resetForm();
        } else {
            setIsLoading(false);
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Emlployee</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة موظف </h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <Formik initialValues={{ ...employees }}
                    onSubmit={(values, { resetForm }) => {
                        submitEmployeesForm(values, { resetForm });
                    }}>
                    {
                        formik => {
                            return (
                                <Form className="row g-3">
                                    <div className="col-md-4">
                                        <label htmlFor="code" className='form-label'>كود الموظف</label>
                                        <Field type="text" className='form-control' name="code" id="code" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="name" className='form-label'>الاسم</label>
                                        <Field type="text" className='form-control' name="name" id="name" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="branch" className='form-label'>اسم الفرع</label>
                                        <Field as="select" name="branch_id"  className='form-control' id="branch_id">
                                            <option value='' hidden disabled>اختر...</option>
                                            {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                                        </Field>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="hourRate" className='form-label'> سعر الساعة </label>
                                        <Field type="text" id="hourRate" className='form-control' name="hourRate" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="category_id" className='form-label'>وظيفته </label>
                                        <Field as="select" type="text" className='form-control' name="category_id" id="category_id" >
                                            <option value='' hidden disabled>اختر...</option>
                                            {employeeCategory.map((cat) => <option key={cat.id} value={cat.id}>{cat?.name}</option>)}
                                        </Field>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="holiday" className='form-label'>يوم الأجازة</label>
                                        <Field as="select" type="text" className='form-control' name="holiday" id="holiday" >
                                            <option value='' hidden disabled>اختر...</option>
                                            <option value="saturday">السبت</option>
                                            <option value="sunday">الأحد</option>
                                            <option value="monday">الإثنين</option>
                                            <option value="tuesday">الثلاثاء</option>
                                            <option value="wednesday">الأربعاء</option>
                                            <option value="thursday">الخميس</option>
                                            <option value="friday">الجمعة</option>
                                        </Field>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="constantIncentive" className='form-label'> قيمة الحافز الثابت</label>
                                        <Field type="text" id="constantIncentive" className='form-control' name="constantIncentive" />
                                    </div>
                                    {employees?.times.map((time, index) => (
                                        <div key={index} className='col-md-12 row'>
                                            <span className='form-label my-3 fw-bolder fs-5'>موعد {time.day === 'saturday' ? 'السبت' : time.day === 'sunday' ? 'الأحد' : time.day === 'monday' ? 'الإثنين' : time.day === 'tuesday' ? 'الثلاثاء' : time.day === 'wednesday' ? 'الأربعاء' : time.day === 'thursday' ? 'الخميس' : time.day === 'friday' ? 'الجمعة' : ''}</span>
                                            <div className="col-md-3">
                                                <label className='form-label'>موعد الحضور</label>
                                                <Field type="time" step='60' id={`time_${index}`} className='form-control' name={`times[${index}].attendance_time`} />
                                            </div>
                                            <div className="col-md-3">
                                                <label className='form-label'>موعد الإنصراف</label>
                                                <Field type="time" step='60' id={`departure_time_${index}`} className='form-control' name={`times[${index}].departure_time`} />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-12">
                                        <label htmlFor="notes" className='form-label'>ملاحظات</label>
                                        <Field type="text" as="textarea" id="notes" className='form-control' name="notes" />
                                    </div>
                                    <div className="col-md-3">
                                        <button type='submit' className='btn btn-primary form-control fs-5'>
                                            {isLoading == true ?
                                                <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                                        </button>
                                    </div>

                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </>
    )
}
