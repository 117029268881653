
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import styles from '../Orders/Orders.module.css'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import { toast } from 'react-toastify';
import Joi from 'joi';
import Select from 'react-select';
import Pagination from '../Pagination/Pagination';


export default function AllOrders() {
  let { accessToken } = useContext(AuthContext);
  let [orders, setOrders] = useState([]);
  let [pagination, setPagination] = useState(null);
  let [currentPage, setCurrentPage] = useState(1); // Current page state
  let [salePoints, setSalePoints] = useState([]);
  let getSalePointsData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/points`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setSalePoints(data.data);
  };
  useEffect(() => {
    getSalePointsData()
  }, []);
  let [users, setUsers] = useState([]);
  let [userOptions, setUserOptions] = useState([]);
  let getUsersData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setUsers(data.data);
  };
  useEffect(() => {
    getUsersData()
  }, []);
  useEffect(() => {
    let mapUser = users?.map((user) => ({
      value: `${user.id}`,
      label: `${user.code}`
    }));
    setUserOptions(mapUser);

  }, [users]);

  let [searchText, setSearchText] = useState('');
  function handleSearchChange(event) {
    setSearchText(event?.target?.value)

  };
  let [filterPointId, setFilterPointId] = useState('');
  function handlePointChange(event) {
    setFilterPointId(event?.target?.value);

  }
  let [filterUserId, setFilterUserId] = useState('');
  function handleUserChange(selectedOption) {

    setFilterUserId(selectedOption.value)
  }
  let [filterIsPaid, setFilterIsPaid] = useState('');
  function handleIsPaidChange(event) {
    setFilterIsPaid(event?.target?.value);
  }
  let [filterDate, setFilterDate] = useState('');
  function handleDateChange(event) {
    setFilterDate(event.target.value)
  }

  let getOrderData = async (page = 1) => {
    let orderResult;
    let urlApi = `${process.env.REACT_APP_API_URL}/api/orders/filter?`;
    if (filterUserId !== undefined && filterUserId.length > 0) {
      urlApi += `user_id=${filterUserId}&`
    }
    if (filterIsPaid !== undefined && filterIsPaid.length > 0) {
      urlApi += `is_paid=${filterIsPaid}&`
    }
    if (filterPointId !== undefined && filterPointId.length > 0) {
      urlApi += `point_id=${filterPointId}&`
    }
    if (filterDate !== undefined && filterDate.length > 0) {
      urlApi += `fromDate=${filterDate}&`
    }
    if (searchText !== undefined && searchText.trim().length > 0) {
      urlApi += `key=${searchText}&`
    }
    urlApi += `page=${page}`
    orderResult = await axios.get(urlApi, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    })

    if (orderResult) {
      setOrders(orderResult.data.data);
      setPagination(orderResult.data.meta); // Set pagination data
      setCurrentPage(page); // Set current page
    }
  };
  useEffect(() => { getOrderData() },
    [filterUserId, filterIsPaid, filterPointId, filterDate, searchText]);
  //for handle page change
  let handlePageChange = (page) => {
    getOrderData(page);
  };

  let [orderId, setOrderId] = useState(''); // for making orders paids on the same system
 //for cancel order 
 let cancelOrder =async(orderId)=>{
  await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/cancelPopUpOrder/${orderId}`, {}, {
    headers: {
        "Authorization": `Bearer ${accessToken}`
    }
}).then((res) => {
    toast.success(res.data.message);
    getOrderData()
}).catch((errors) => {
    try {
     toast.error(errors?.response?.data?.message)
    } catch (error) {
        toast.error('حدث خطأ ما')
    }
});  
}

  let showOrders = () => {
    if (orders.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
          <table dir="rtl" className='table  table-hover text-center align-middle table-responsive-list '>
            <thead className='table-primary  no-wrap-heading'>
              <tr>
                <th> تاريخ الإنشاء</th>
                <th> تاريخ القبول</th>
                <th> نقطة اليبع</th>
                <th>اسم الموظف</th>
                <th>هاتف الموظف</th>
                <th>كود العميل</th>
                <th>المنطقة </th>
                <th>قيمة الأوردر</th>
                <th>الإجمالي</th>
                <th>إلغاء الأوردر</th>
                <th>المدفوع</th>
                <th> الباقي</th>
                
                
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                 <tr key={order.id} className={order.unpaid > 0 ? 'table-danger' : ''}>
                <td data-label="تاريخ الإنشاء"  >{order.created_at}</td>
                <td data-label="تاريخ الإنشاء"  >{order.accepted_at}</td>
                <td data-label="نقطة البيع">{order?.sale_point?.name}</td>
                <td data-label="اسم الموظف">{order?.delivery_man?.name}</td>
                <td data-label="هاتف الموظف">{order?.delivery_man?.phone}</td>
                <td data-label="كود العميل">{order?.customer?.code}</td>
                <td data-label=" المنطقة">{order?.area?.name}</td>
                <td data-label="قيمة الأوردر">{order.cost}</td>
                <td data-label="الإجمالي">{order.total_ammount}</td>
                <td data-label="إلغاء الأوردر">
                  <span className='bg-danger p-1 text-white rounded' onClick={()=>{cancelOrder(order.id)}}>إلغاء</span>
                </td>
                <td data-label="المدفوع">{order.paid}</td>
                <td data-label="الباقي" >{order.unpaid}</td>
        
              </tr>

             ) )}
            </tbody>

          </table>
        </div>
      )
    } else {
      return (
        <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
          {orders.length <= 0 && searchText.length <= 0 && filterPointId.length <= 0 && filterUserId.length <= 0 && filterIsPaid.length <= 0 && filterDate.length <= 0 ?
            <i className='fa fa-spinner fa-spin  fa-3x'></i>
            : <div className='alert alert-danger w-50 text-center'>لا يوجد أوردرات</div>
          }
        </div>
      )
    }
  };
  let showSalePoints = () => {
    if (salePoints.length > 0) {
      return (
        <div >
          {salePoints.map((point) => <div key={point.id}>
            <div className={`row`}>
              <div className="col-4 ">
                <p className='bg-secondary px-1 text-white rounded'>{point?.name}</p>
              </div>
              <div className="col-4 ">
                <p className='bg-secondary px-1 text-white rounded '>{point?.unpaid_balance}</p>
              </div>
            </div>
          </div>)}

        </div>
      )
    } else {
      return (
        <div className='h-75' >
          <div className=' d-flex justify-content-center h-100  align-items-center' >
            <i className='fa fa-spinner fa-spin fa-2x '></i>
          </div>
        </div>
      )
    }
  }

  let [openPaidModal, setOpenPaidModal] = useState(false)

  function openModal() {
    setOpenPaidModal(true);
  };
  function closeModal() {
    setOpenPaidModal(false);
  };

  let [paid, setPaid] = useState({
    paid_value: '',
  });
  let getInputValue = (event) => {
    let myPaids = { ...paid }; //deep copy
    myPaids[event.target.name] = event.target.value;
    setPaid(myPaids);
  };

  let sendUnpaidAmountToApi = async (ordId) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/pay/${ordId}`, paid, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getOrderData()
    }).catch((errors) => {
      toast.error(errors?.response?.data?.message);

    })
  };
  let validatePaidsForm = () => {
    const schema = Joi.object({
      paid_value: Joi.string().required(),
    });
    return schema.validate(paid, { abortEarly: false });
  };

  let submitUnpaidAmountToApi = (e) => {
    e.preventDefault();
    let validation = validatePaidsForm();
    if (!validation.error) {
      sendUnpaidAmountToApi(orderId)
    } else {
      try {
        validation.error.details.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");
      }
    }

  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
      </Helmet>
      <div className=" my-3  row mx-2  " dir='rtl'>
        <div className="col-md-2 mb-2">
          <NavLink to='/popOrders' className='btn btn-warning'> الأوردرات المعلقة  </NavLink>
        </div>
        <div className="col-md-2 mb-2">
          <NavLink to='/popOrders/add' className='btn btn-danger'>إضافة أوردر للطيارين </NavLink>
        </div>
        <div className="col-md-2 mb-1">
          <Select
            options={userOptions}
            onChange={handleUserChange}
            isSearchable={true}
            placeholder="اختر موظف"
          />
        </div>
        <div className="col-md-2 mb-1">
          <select name="is_paid" defaultValue={0} className='form-control' id="role"
            onChange={handleIsPaidChange}>
            <option value={0} hidden disabled>اختر مسدد أو غير مسدد </option>
            <option value={"paid"} >مسدد</option>
            <option value={"unpaid"} >غير مسدد</option>
          </select>
        </div>
        <div className="col-md-2 mb-1">
          <select name="point_id" defaultValue={0} className='form-control' id="point_id"
            onChange={handlePointChange}>
            <option value={0} hidden disabled>اختر نقطة بيع</option>
            {salePoints.map((point) => <option key={point.id} value={point.id} >{point.name}</option>)}
          </select>
        </div>
        <div className="col-md-2 mb-1">
          <input type="date" className='form-control mt-1' onChange={handleDateChange} />
        </div>
        <div className="col-md-3 m-auto" >
          <input type="text" className='form-control text-end mt-1 ' placeholder='بحث عن أوردر...' onChange={handleSearchChange} />
        </div>
        <div className="col-md-4  m-auto  text-center mt-1">
              {showSalePoints()}
            </div>
      </div>
      {/* paid modal */}
      {openPaidModal && <div id="paidModal" className={`${styles.modal} `}>
        <div className={`${styles.modal_content}`}>
          <span className={`${styles.close} fs-3`} onClick={closeModal} >&times;</span>
          <form onSubmit={submitUnpaidAmountToApi} >
            <div className="my-2">
              <label htmlFor="paid" className='form-label mt-4'>القيمة المسددة</label>
              <input type="text" name="paid_value" id="paid" className='form-control' onChange={getInputValue} />
            </div>
            <button type='submit' className=' btn text-bg-success'>سدد</button>
          </form>
        </div>
      </div>}

      <div className="text-center mb-2">
        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      {showOrders()}
    </>
  )
}
