// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_color__XTR29{
    color: #5A367A;
    font-size: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".color{\n    color: #5A367A;\n    font-size: 5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color": `NotFound_color__XTR29`
};
export default ___CSS_LOADER_EXPORT___;
