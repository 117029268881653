import React from 'react';
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import SalePointModal from '../SalePointModal/SalePointModal';

export default function MasterLayout() {

  return (
    <>
      <SalePointModal/>
      <div className=" g-0 row">
        <div className="col-10 show-in-print">
          <div className='hide-in-print'>
          <Navbar />
          </div>
          <main>
            <Outlet  />
          </main>

        </div>
        <div className='col-auto hide-in-print'>
          <Sidebar />
        </div>
      </div>

    </>
  )
}
