import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';

export default function Areas() {
    const { accessToken } = useContext(AuthContext);
    const [areas, setAreas] = useState({
        name: '',
        points: ''
    });
    const [areasData, setAreasData] = useState([]);
    const [areaId, setAreaId] = useState('');
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        getAreasData();
    }, []);

    let getAreasData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/areas`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            console.log(data);
            setAreasData(data);
        } catch (error) {
            toast.error('حدث خطأ أثناء جلب البيانات');
        }
    }
    let showAreas = () => {
        if (areasData.length > 0) {
            return (
                <div className="bg-white mx-3 p-3 border table-responsive">
                    <table dir="rtl" className='table table-bordered  table-hover text-center table-responsive-list '>
                        <thead className='table-secondary no-wrap-heading' >
                            <tr >
                                <th>المنطقة</th>
                                <th>النقاط</th>
                                <th>أوردرات الشهر قبل السابق</th>
                                <th>أوردرات الشهر السابق</th>
                                <th>أوردرات الشهر الحالي</th>
                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {areasData?.map((area, index) => <tr key={area.id}>
                                <td data-label="المنطقة">{area?.name}</td>
                                <td data-label="النقاط">{area?.points}</td>
                                <td data-label="أوردرات الشهر قبل السابق">{area?.monthBeforeLastTotalValue} , {area?.monthBeforeLastOrders} , {area?.monthBeforeLastAverageValue}</td>
                                <td data-label="أوردرات الشهر السابق">{area?.lastMonthTotalValue} , {area?.lastMonthOrders} , {area?.lastMonthAverageValue}</td>
                                <td data-label="أوردرات الشهر الحالي">{area?.currentMonthTotalValue} , {area?.currentMonthOrders} , {area?.currentMonthAverageValue}</td>
                                <td data-label="خيارات">
                                <i className='bi bi-trash text-danger fs-5' onClick={() => deleteArea(area.id)}></i>
                                <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => handleEdit(index)}></i>
                                </td>
                            </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            )
        } else {

            return (
                <div className='h-75'>
                    <div className=' d-flex justify-content-center h-100 align-items-center'>
                        <i className='fa fa-spinner fa-spin fa-2x'></i>
                    </div>
                </div>
            )

        }
    }

    let getInputValue = (event) => {
        const { name, value } = event.target;
        setAreas(prevState => ({ ...prevState, [name]: value }));
    };

    let submitAreaOrEditedArea = async (event) => {
        event.preventDefault();
        if (!areas.name.trim()) {
            toast.error("المنطقة مطلوبة");
            return;
        }
        try {
            if (editing) {
                let { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/areas/${areaId}`, areas, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                toast.success(data.message);
            } else {
                let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/areas`, areas, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                toast.success(data.message);
            }
            setEditing(false);
            setAreas({ name: '', points: '' });
            getAreasData();
        } catch (error) {
            toast.error('حدث خطأ أثناء العملية');
        }
    };

    let handleEdit = (index) => {
        const area = areasData[index];
        //putting name and points in the inputz
        setAreas({ name: area.name, points: area.points });
        setAreaId(area.id);
        setEditing(true);
    };
    let deleteArea = async (areaId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/areas/delete/${areaId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getAreasData();
        } catch (error) {
            toast.error('حدث خطأ أثناء العملية');
        }
    };

    return (
        <div className="container-fluid my-2">
            <div className='row gx-1'>
                <div className="col-md-3">
                    <h4 className='alert alert-primary text-center'>إضافة منطقة</h4>
                    <form onSubmit={submitAreaOrEditedArea}>
                        <div className='w-75 m-auto'>
                            <div>
                                <label htmlFor="name" className='form-label'>اسم المنطقة </label>
                                <input type="text" name="name" className='form-control' value={areas?.name} onChange={getInputValue} />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="points" className='form-label'>النقاط</label>
                                <input type="text" name="points" className='form-control' value={areas?.points} onChange={getInputValue} />
                            </div>
                            <button type='submit' className={`btn ${editing ? 'btn-outline-danger' : 'btn-outline-primary'} mt-3`}>
                                {editing ? 'تعديل' : 'إضافة'}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-9 card p-2 mt-5">
                    <h4 className='text-center text-bg-secondary py-2 rounded'>المناطق</h4>
                    {showAreas()}
               
                </div>
            </div>
        </div>
    );
}
