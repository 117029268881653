import React from 'react'
import AddNotification from '../../Notifications/AddNotification'

export default function AddDoctorNotifications() {
  return (
<>
<AddNotification/>
</>
  )
}
