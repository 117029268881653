import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context/AuthStore';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Field, Form, Formik } from "formik";
import Joi from 'joi';

export default function EditeAbuQirForm({ formId, getFormsData, clients }) {
  let { accessToken } = useContext(AuthContext);
  let [clientOptionsEdited, setClientOptionsEdited] = useState([]);
  let [oneForm, setOneForm] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [abuQirForm, setAbuQirForm] = useState({
    isAContribution: '',
    client_id: '',
    elements: []
  })
  const getOneForm = async () => {
    if (formId) {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirForms/show/${formId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setOneForm(data.data);
      setAbuQirForm({
        isAContribution: data.data.isAContribution || '',
        client_id: data.data.client?.id || '',
        elements: data.data.elements.map(element => ({
          id: element.id,
          price: element.price,
        })) || []
      });
    }


  };
  useEffect(() => {
    getOneForm();
  }, [formId]);

  useEffect(() => {
    const mapClient = clients?.map(client => ({
      value: `${client.id}`,
      label: `${client.registrationNumber}`,
    }));
    setClientOptionsEdited(mapClient);
  }, [clients]);
 

  let sendEditedDataToApi = async (values) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/abuQirForms/${formId}`, values, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message, {
        position: 'top-center'
      });
      setIsLoading(false);
      getFormsData()

    }).catch((errors) => {
      setIsLoading(false);
      try {
        const errorList = errors?.response?.data?.message;
        if (errorList !== undefined) {
          Object.keys(errorList).map((err) => {
            errorList[err].map((err) => {
              toast.error(err);
            })
          });
        }
      } catch (error) {
        toast.error('حدث خطأ ما')
      }
    });
  };

  let validateEditedDataForm = (values) => {
    const schema = Joi.object({
      isAContribution: Joi.number().allow(''),
      client_id: Joi.number().allow(''),
      elements: Joi.any().allow('')
    });
    return schema.validate(values, { abortEarly: false });
  };

  let submitEditedForm = (values) => {
    const initialForm = { ...abuQirForm };
    // Filter out unchanged fields
    const changedValues = {};
    Object.keys(values).map(key => {    
        if (JSON.stringify(values[key]) !== JSON.stringify(initialForm[key])) {
            changedValues[key] = values[key];
        }
    });
 
     setIsLoading(true);        
    let validation = validateEditedDataForm(changedValues);
    if (!validation.error) {
      sendEditedDataToApi(changedValues);
    } else {
      setIsLoading(false);
      try {
        validation.error.details.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");

      }
    }
  };
 

  return (
    <>
      <Formik
        initialValues={{
          ...abuQirForm
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          submitEditedForm(values)
        }}
      >
        {formik => (
          <Form className="row gy-3">
            <div className="col-md-4">
              <label htmlFor="client_id" className='form-label'>رقم قيد العميل</label>
              <Select
  name="client_id"
  options={clientOptionsEdited}
  onChange={(selectedOption) => {
    formik.setFieldValue("client_id", selectedOption.value); // تحديث قيمة formik مباشرة
  }}
  isSearchable={true}
  placeholder="بحث ..."
/>

            </div>

            <div className="col-md-4">
              <label htmlFor="elements" className='form-label'>عناصر الإستمارة</label>
              {formik.values.elements.map((element, index) => (
                <div key={index}>
                  <Field
                    type="hidden"
                    name={`elements[${index}].id`}
                    value={element.id}
                  />
                  <Field
                    type="text"
                    name={`elements[${index}].price`}
                    className='form-control my-1'
                  />

                </div>
              ))}
            </div>

            <div className="col-md-4">
              <label htmlFor="isAContribution" className='form-label'>مساهمة؟</label>
              <Field as="select" name="isAContribution" className='form-control'>
                <option value="" hidden disabled>اختر...</option>
                <option value={1}>نعم</option>
                <option value={0}>لا</option>
              </Field>
            </div>

            <div className="col-md-3">
              <button type='submit' className='btn btn-primary form-control fs-5'>
                {isLoading == true ?
                  <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
