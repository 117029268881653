
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Pagination from '../Pagination/Pagination';



export default function AdjusmentsActions() {
    let { accessToken } = useContext(AuthContext);
    let [adjusmentsActions, setAdjusmentsActions] = useState([]);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [employees, setEmployees] = useState([]);
    let [employeesOptions, setEmployeesOptions] = useState([]);
    let getEmployeesData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees?noPaginate=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setEmployees(data.data);
        } catch (error) {
            toast.error('حدث خطأ ما.')
        }
    };
    useEffect(() => {
        getEmployeesData()
        getEmployeeCategory()
        getBranches()
    }, []);
    useEffect(() => {
        let mapEmployees = employees?.map((emp) => ({
            value: `${emp.id}`,
            label: `${emp.code}`,
        }));
        setEmployeesOptions(mapEmployees);
    }, [employees]);

    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }
    let [filterMonth, setFilterMonth] = useState('');
    function handleMonthChange(event) {
        setFilterMonth(event?.target?.value);
    }
    let [filterEmployeeId, setFilterEmployeeId] = useState('');
    function handleEmployeeChange(selectedOption) {
        setFilterEmployeeId(selectedOption.value)
    }
    let [filterCategoryId, setFilterCategoryId] = useState('');
    function handleCategoryChange(event) {
        setFilterCategoryId(event?.target?.value);
    }
    let [filterBranchId, setFilterBranchId] = useState('');
    function handleBranchIdChange(event) {
        setFilterBranchId(event?.target?.value);
    }

    let getAdjusmentsActionsData = async (page = 1) => {
        let adjusmentsActions;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/monthly-accounts/adjusmentsActions?`;
        if (filterEmployeeId !== undefined && filterEmployeeId.length > 0) {
            urlApi += `employee_id=${filterEmployeeId}&`
        }
        if (filterCategoryId !== undefined && filterCategoryId.length > 0) {
            urlApi += `category_id=${filterCategoryId}&`
        }
        if (filterBranchId !== undefined && filterBranchId.length > 0) {
            urlApi += `branch_id=${filterBranchId}&`
        }
        if (filterMonth !== undefined && filterMonth.length > 0) {
            urlApi += `month_year=${filterMonth}&`
        }
        urlApi += `page=${page}&`
     
        try {
            adjusmentsActions = await axios.get(urlApi, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            if (adjusmentsActions) {
                setAdjusmentsActions(adjusmentsActions.data.data);
                setPagination(adjusmentsActions.data.meta); // Set pagination data
                setCurrentPage(page); // Set current page
            }
        } catch (error) {
            toast.error('حدث خطأ ما')
        }

    };
    useEffect(() => { getAdjusmentsActionsData() },
        [filterCategoryId, filterBranchId, filterEmployeeId, filterMonth]);
    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }

    let showAdjusmentsActions = () => {
        if (adjusmentsActions.length > 0) {
            return (
                <>
                    <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                        <table dir="rtl" className='table table-bordered  table-hover text-center align-middle table-responsive-list '>
                            <thead className='table-primary  no-wrap-heading'>
                                <tr>
                                    <th>وقت الإضافة</th>
                                    <th>كود</th>
                                    <th>اسم الموظف</th>
                                    <th>القيمة المضافة</th>
                                    <th>نوع الإضافة </th>
                                    <th>ملاحظة</th>




                                </tr>
                            </thead>
                            <tbody>
                                {adjusmentsActions.map((adjust) => (
                                    <tr key={adjust.id}>
                                        <td data-label="وقت الإضافة" >{adjust?.formatted_created_at}</td>
                                        <td data-label="كود" >{adjust?.employee?.code}</td>
                                        <td data-label="اسم الموظف" >{adjust?.employee?.name}</td>
                                        <td data-label="اسم الموظف" >{`${adjust?.value} ${adjust.dayDate !== null ? `ساعة يوم ${adjust.dayDate} `  :''}` }</td>
                                        <td data-label="نوع الإضافة" >{adjust.type =='additional_hours' ? 'ساعات مضافة' : adjust.type == 'debts' ? 'مديونية': adjust.type=='discounted_hours' ? 'ساعات خصم' : adjust.type == 'incentives' ? 'حوافز': adjust.type == 'additional_day' ? 'يوم مضاف' : adjust.type == 'cash_penalties' ? 'جزاءات': adjust.type == 'discounted_day'? 'خصم يوم' : ''}</td>
                                        <td data-label="ملاحظة" >{adjust?.notes}</td>
                           


                                    </tr>

                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {adjusmentsActions.length <= 0 && filterCategoryId.length <= 0 && filterEmployeeId.length <= 0 && filterMonth.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-3x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد بيانات متاحة</div>
                    }
                </div>
            )
        }
    }

    //for handle page change
    let handlePageChange = (page) => {
        getAdjusmentsActionsData(page);
    };
    return (
        <>



            {/* Filters */}
            <div className="row m-2 gy-2" dir='rtl'>
                <div className="col-md-3">
                    <select name="branch_id" defaultValue={0} className='form-control'
                        onChange={handleBranchIdChange}>
                        <option value={0} hidden disabled>اختر الفرع...</option>
                        {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-3">
                    <Select
                        options={employeesOptions}
                        onChange={handleEmployeeChange}
                        isSearchable={true}
                        placeholder="بحث عن موظف..."
                    />
                </div>
                <div className="col-md-3">
                    <select defaultValue={0} type="text" className='form-control' name="category_id" onChange={handleCategoryChange} >
                        <option value={0} hidden disabled>اختر الوظيفة...</option>
                        {employeeCategory.map((cat) => <option key={cat.id} value={cat.id} >{cat?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="month"
                        className='form-control'
                        onChange={handleMonthChange}
                    />
                </div>
            </div>

            {/* Display adjusmentsActions Data */}

            {(filterEmployeeId.length > 0 || filterCategoryId.length > 0 || filterMonth.length > 0 || filterBranchId.length > 0) && (
                <>
                    <div className="text-center mb-2">
                        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
                    </div>
                    <div>
                        {showAdjusmentsActions()}
                    </div>
                </>
            )}



        </>
    )
}
