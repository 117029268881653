import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';
import { useRef } from 'react';

export default function ResetPassword() {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let formRef = useRef(null)
    let [password, setPassword] = useState({
        password: '',
        new_password: '',
        new_password_confirmation: ''

    });
    let getInputValue = (event) => {
        let myPassword = { ...password }; //deep copy
        myPassword[event.target.name] = event.target.value;
        setPassword(myPassword);
    };
    let sendPasswordDataToApi = async () => {
         await axios.post(`${process.env.REACT_APP_API_URL}/api/users/changePassword `, password, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            setIsLoading(false);
            formRef.current.reset();

        }).catch((errors) => {
            setIsLoading(false);
            const errorList = errors?.response?.data?.error;
            if (errorList !== undefined) {
                Object.keys(errorList)?.map((err) => {
                    errorList[err]?.map((err) => {
                        toast.error(err);
                    })
                });
            } else {
                toast.error("حدث خطأ ما");
            }
        });
    };

    let validatePasswordForm = () => {
        const schema = Joi.object({
            password: Joi.string().required(),
            new_password: Joi.string().required(),
            new_password_confirmation: Joi.ref('new_password'),
        });
        return schema.validate(password, { abortEarly: false });
    };
    let submitPasswordForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validatePasswordForm();
        if (!validation.error) {
            sendPasswordDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add User</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>تغيير كلمة السر</h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitPasswordForm}>
                    <div className="row gy-3">
                        <div className="col-md-7">
                            <label htmlFor="password" className='form-label'>كلمة السر القديمة</label>
                            <input type="password" className='form-control' name="password" 
                                onChange={getInputValue} />
                        </div>
                        <div className="col-md-7">
                            <label htmlFor="new_password" className='form-label'>تأكيد كلمة السر</label>
                            <input type="password" className='form-control' name="new_password"
                                 onChange={getInputValue} />
                        </div>
                        <div className="col-md-7">
                            <label htmlFor="new_password_confirmation" className='form-label'>تأكيد كلمة السر</label>
                            <input type="password" className='form-control' name="new_password_confirmation"
                                 onChange={getInputValue} />
                        </div> 
                    </div>
                        <div className="col-md-3 my-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تغيير'}
                            </button>
                        </div>
                </form>
            </div>
        </>
    )
}
