
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import { toast } from 'react-toastify';

export default function AllUsers() {

  let { accessToken } = useContext(AuthContext);
  let [users, setUsers] = useState([]);

  let getUserData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setUsers(data.data);
  }
  useEffect(() => { getUserData() }, []);
  //paid all money for all orders for every one
  let makePaidForAllOrders = async (userId) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/payAllOrders/${userId}`, {}, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getUserData()
    }).catch((errors) => {
      toast.error(errors?.response?.data?.message);
    })
  }
  let [userId, setUserId] = useState('')
  let showUsers = () => {
    if (users.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 border my-3 ">
          <table dir="rtl" responsive='sm' className='table table-bordered  table-hover text-center table-responsive-list '>
            <thead className='table-primary' >
              <tr >
                <th>رقم</th>
                <th>كود المستخدم</th>
                <th>الاسم</th>
                <th>الفرع</th>
                <th>الوظيفة</th>
                <th>رقم الهاتف</th>
                <th>إجمالي المبلغ</th>
                <th> سداد</th>

              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                let unpaidAmountNumeric = parseFloat(user.unpaidAmount.replace(/,/g, '')); // تحويل القيمة إلى رقم بعد إزالة الفاصلة
                return unpaidAmountNumeric > 0.00 && (
                  <tr key={user.id}>
                    <td data-label="#">{++index}</td>
                    <td data-label="كود المستخدم">{user?.code}</td>
                    <td data-label="اسم المستخدم">{user?.name}</td>
                    <td data-label="الفرع">{user?.branch?.name}</td>
                    <td data-label="الوظيفة">{user?.role == 'admin' ? 'أدمن' : user.role == 'doctor' ? 'صيدلي' : user.role == 'delivery' ? 'طيار' : user.role == 'purchases' ? 'مشتريات' : user.role == 'casher' ? 'كاشير' : ''}</td>
                    <td data-label="رقم الهاتف">{user?.phone}</td>
                    <td data-label="إجمالي المبلغ">{user?.unpaidAmount}</td>
                    <td data-label=" سداد">
                      <i className={` fs-5 ${user?.unpaidAmount === 0 ? 'bi bi-bag-check-fill text-success' : 'text-danger bi bi-bag-x-fill'}`} onClick={() => { setUserId(user.id) }} data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                    </td>
                  </tr>)
              })}
            </tbody>

          </table>
        </div>
      )
    } else {

      return (
        <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
          <i className='fa fa-spinner fa-spin  fa-2x'></i>
        </div>
      )

    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>

      {/* paid modal  */}

      <div dir='rtl' className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5" id="exampleModalLabel">  هل أنت متأكد من تسديد المبلغ كاملا؟    </h1>
            </div>
            <div className="modal-body">
              <div className="my-2">
                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                <button type="button" className="btn btn-primary" onClick={() => { makePaidForAllOrders(userId) }}>تأكيد </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUsers()}
    </>
  )
} 
