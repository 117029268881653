import React from 'react'
import AddCompany from '../../Companies/AddCompany'

export default function AddPurchasesCompanies() {
  return (
   <>
   <AddCompany/>
   </>
  )
}
