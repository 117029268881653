
import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';
import { useRef } from 'react';
import Select from 'react-select';

export default function AddDebt() {
  let { accessToken } = useContext(AuthContext);
  let [isLoading, setIsLoading] = useState(false);
  let formRef = useRef(null)
  const employeeCodeRef = useRef(null);
  let [debts, setDebts] = useState({
    debts:'',
    month_year:'',
    notes: '',
  });
  let [employeeId, setEmployeeId] = useState('');
  let [categoryId, setCategoryId] = useState('');
  let [addAll, setAddAll] = useState(false);
  let [employees, setEmployees] = useState([]);
  let [employeesOptions, setEmployeesOptions] = useState([]);
  let getEmployeesData = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees?noPaginate=1`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setEmployees(data.data);
    } catch (error) {
      toast.error('حدث خطأ ما.')
    }
  };

  useEffect(() => {
    getEmployeesData()
  }, []);
  useEffect(() => {
    let mapEmployees = employees?.map((emp) => ({
      value: `${emp.id}`,
      label: `${emp.name}`,
    }));
    setEmployeesOptions(mapEmployees);
  }, [employees]);
  let [employeeCategory, setEmployeeCategory] = useState([]);
  let getEmployeeCategory = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setEmployeeCategory(data)
  }
  useEffect(() => {
    getEmployeeCategory()
  }, []);

  let getInputValue = (event) => {
    const { name, value } = event.target;
    setDebts(prevState => ({ ...prevState, [name]: value }));
  };
  let sendDebtsDataToApi = async () => {
    let apiUrl = '';
    if (addAll) { // Check if addAll is true
      apiUrl = `${process.env.REACT_APP_API_URL}/api/monthly-accounts/all`;
    } else if (categoryId.length > 0) {
      apiUrl = `${process.env.REACT_APP_API_URL}/api/monthly-accounts/${categoryId}`;
    } else if (employeeId.length > 0) {
      apiUrl = `${process.env.REACT_APP_API_URL}/api/monthly-accounts/one/${employeeId}`;
    }
    await axios.post(apiUrl, debts, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message, {
        position: 'top-center'
      });
      setIsLoading(false);
      formRef.current.reset();
      employeeCodeRef.current.clearValue();
      setCategoryId('')
      setEmployeeId('')
      setDebts({
        debts:'',
        month_year:'',
        notes: '',
      })
    }).catch((errors) => {
      setIsLoading(false);
      const errorList = errors?.response?.data?.error;
      if (errorList !== undefined) {
        Object.keys(errorList)?.map((err) => {
          errorList[err]?.map((err) => {
            toast.error(err);
          });
        });
      } else {
        toast.error("حدث خطأ ما");
      }
    });
  };
  let validateForm = () => {
    const schema = Joi.object({
      month_year:Joi.string().required(),
      debts: Joi.string().allow(''),
      notes: Joi.string().allow(''),
    });
    return schema.validate(debts, { abortEarly: false });
  };
  let submitForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let validation = validateForm();
    if (!validation.error) {
      sendDebtsDataToApi();
    } else {
      setIsLoading(false);
      try {
        validation.error.details.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Monthly Accounts </title>
      </Helmet>
      <div className="mx-5 p-3 rounded rounded-3 bg-white">
        <form ref={formRef} onSubmit={submitForm}>
          <div className="row gy-2">
            <div className="col-md-5">
              <label htmlFor="additional_hours" className='form-label'>اسم الموظف </label>
              <Select
                ref={employeeCodeRef}
                options={employeesOptions}
                isSearchable={true}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setEmployeeId(selectedOption.value);
                  } else {
                    setEmployeeId(''); // or handle the null case appropriately
                  }
                }}
                placeholder="بحث عن موظف..."
              />
            </div>
            <div className="col-md-5">
              <label htmlFor="category_id" className='form-label'>فئة معينة </label>
              <select defaultValue={0} type="text" className='form-control' name="category_id" onChange={(e) => setCategoryId(e.target.value)} >
                <option value={0} hidden disabled>اختر...</option>
                {employeeCategory.map((cat) => <option key={cat.id} value={cat.id} >{cat?.name}</option>)}
              </select>
            </div>
          </div>
          <div className='col-md-5 my-3'>
            <label className='fw-bold ms-2' >إضافة للكل</label>
            <input type="checkbox" onChange={(e) => setAddAll(e.target.checked)} />
          </div>
          <div className="col-md-4 my-3">
            <label htmlFor="month_year" className='form-label'>مديونية على شهر؟</label>
            <span className='text-danger fs-5 fw-bold'> * </span>
            <input type="month" className='form-control' name="month_year"
              onChange={getInputValue} />
          </div>
         
          <div className="col-md-4 my-3">
            <label htmlFor="debts" className='form-label'>قيمة المديونية </label>
            <input type="text" className='form-control' name="debts"
              onChange={getInputValue} />
          </div>
          <div className="col-md-4">
            <label htmlFor="notes" className='form-label'>ملاحظة</label>
            <textarea type='text' name="notes" id="notes" className='form-control' onChange={getInputValue} />
          </div>
         
          <div className="col-md-3 my-3">
            <button type='submit' className='btn btn-primary form-control fs-5'>
              {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
