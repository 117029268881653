import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import '../../../src/App.css';
import MasterLayout from '../Layouts/MasterLayout';
import NotFound from '../NotFound/NotFound';
import Login from '../Login/Login';
import Clients from '../Clients/Clients';
import { useContext } from 'react';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import AddClient from '../Clients/AddClient';
import EditeClient from '../Clients/EditeClient';
import ClientDetails from '../Clients/ClientDetails';
import Orders from '../Orders/Orders';
import DeleteOrder from '../Orders/DeleteOrder';
import EditeOrder from '../Orders/EditeOrder';
import OrderDetails from '../Orders/OrderDetails';
import { ToastContainer } from 'react-toastify';
import DeleteClient from '../Clients/DeleteClient';
import { Offline } from 'react-detect-offline';
import { AuthContext } from '../../Context/AuthStore';
import DeliveryLayout from '../Layouts/DeliveryLayout';
import DeliveryOrders from '../Delivery/DeliveryOrders';
import SalePoints from '../SalePoints/SalePoints';
import DoctorLayout from '../Layouts/DoctorLayout';
import DoctorOrders from '../Doctor/DoctorOrders';
import Branches from '../Branches/Branches';
import AddShortComings from '../ShortComings/AddShortComings';
import Purchases from '../Purchases/Purchases';
import PurchasesDeatils from '../Purchases/PurchasesDeatils';
import EditePurchases from '../Purchases/EditePurchases';
import DeletePurchases from '../Purchases/DeletePurchases';
import CustomersService from '../CustomersService/CustomersService';
import AddStatus from '../CustomersService/AddStatus';
import DoctorPurchases from '../Purchases/DoctorPurchases';
import DoctorPurchasesDeatils from '../Purchases/DoctorPurchasesDeatils';
import EditePurchasesDoctor from '../Doctor/EditePurchasesDoctor';
import Offers from '../Offers/Offers';
import AddOffer from '../Offers/AddOffer';
import DeleteOffer from '../Offers/DeleteOffer';
import EditeOffer from '../Offers/EditeOffer';
import Companies from '../Companies/Companies';
import DoctorOffers from '../Doctor/Offers/DoctorOffers';
import AddOfferDoctor from '../Doctor/Offers/AddOfferDoctor';
import EditeOffersDoctor from '../Doctor/Offers/EditeOffersDoctor';
import AddCompany from '../Companies/AddCompany';
import EditeCompany from '../Companies/EditeCompany';
import Rules from '../Rules/Rules';
import AddRules from '../Rules/AddRules';
import EditeRule from '../Rules/EditeRule';
import InventoryProducts from '../InventoryProducts/InventoryProducts';
import Notifications from '../Notifications/Notifications';
import AddNotification from '../Notifications/AddNotification';
import DoctorNotifications from '../Doctor/Notifications/DoctorNotifications';
import AddDoctorNotifications from '../Doctor/Notifications/AddDoctorNotifications';
import AddInventoryProducts from '../InventoryProducts/AddInventoryProducts';
import AddDoctorInventoryProducts from '../Doctor/InventoryProducts/AddDoctorInventoryProducts';
import DoctorInventoryProducts from '../Doctor/InventoryProducts/DoctorInventoryProducts';
import SellingIncentives from '../SellingIncentives/SellingIncentives';
import AddSellingIncentives from '../SellingIncentives/AddSellingIncentives';
import DoctorRules from '../Doctor/Rules/DoctorRules';
import DeliveryRules from '../Delivery/Rules/DeliveryRules';
import ReasonsOfIncentives from '../SellingIncentives/ReasonsOfIncentives';
import DeleteSellingIncentives from '../SellingIncentives/DeleteSellingIncentives';
import EditeSellingIncentives from '../SellingIncentives/EditeSellingIncentives';
import DoctorSellingIncentives from '../Doctor/SellingIncentives/DoctorSellingIncentives';
import PurchasesLayout from '../Layouts/PurchasesLayout';
import PurchasesUser from '../PurchasesUser.jsx/Purchases/PurchasesUser';
import PurchasesInventoryProducts from '../PurchasesUser.jsx/InventoryProducts/PurchasesInventoryProducts';
import PurchasesCompanies from '../PurchasesUser.jsx/Companies/PurchasesCompanies';
import AddPurchasesCompanies from '../PurchasesUser.jsx/Companies/AddPurchasesCompanies';
import EditePurchasesCompanies from '../PurchasesUser.jsx/Companies/EditePurchasesCompanies';
import AddPurchasesInventoryProducts from '../PurchasesUser.jsx/InventoryProducts/AddPurchasesInventoryProducts';
import EditePurchasesUser from '../PurchasesUser.jsx/Purchases/EditePurchasesUser';
import DeletePurchasesUser from '../PurchasesUser.jsx/Purchases/DeletePurchasesUser';
import PurchasesUserDetails from '../PurchasesUser.jsx/Purchases/PurchasesUserDetails';
import Areas from '../Areas/Areas';
import PurchasesNotifications from '../PurchasesUser.jsx/Notifications/PurchasesNotifications';
import AddPurchasesNotifications from '../PurchasesUser.jsx/Notifications/AddPurchasesNotifications';
import Home from '../Admin/Home/Home';
import Users from '../Admin/Users/Users';
import AddUser from '../Admin/Users/AddUser';
import DeleteUser from '../Admin/Users/DeleteUser';
import EditeUser from '../Admin/Users/EditeUser';
import UserDetails from '../Admin/Users/UserDetails';
import WorkPolicies from '../WorkPolicies/WorkPolicies';
import AddWorkPolicies from '../WorkPolicies/AddWorkPolicies';
import EditeWorkPolicies from '../WorkPolicies/EditeWorkPolicies';
import DoctorWorkPolicies from '../Doctor/WorkPolicies/DoctorWorkPolicies';
import DeliveryWorkPolicies from '../Delivery/WorkPolicies/DeliveryWorkPolicies';
import AddPopUpOrders from '../Orders/AddPopUpOrders';
import AddPopUpOrdersDoctor from '../Doctor/AddPopUpOrdersDoctor';
import PopUpOrders from '../Orders/PopUpOrders';
import DoctorPopUpOrders from '../Doctor/DoctorPopUpOrders';
import DeliveryPopUpOrders from '../Delivery/DeliveryPopUpOrders';
import CasherLayout from '../Layouts/CasherLayout';
import AllOrders from '../Casher/AllOrders';
import ClientsData from '../Casher/ClientsData';
import AllUsers from '../Casher/AllUsers';
import AbuQirClients from '../AbuQir/AbuQirClients/AbuQirClients'
import AddAbuQirClients from '../AbuQir/AbuQirClients/AddAbuQirClients'
import AbuQirForms from '../AbuQir/AbuQirForms/AbuQirForms';
import AddAbuQirForms from '../AbuQir/AbuQirForms/AddAbuQirForms';
import ClaimDetails from '../AbuQir/AbuQirForms/ClaimDetails';
import Employees from '../Employees/Employees';
import AddEmployee from '../Employees/AddEmployee';
import AttendanceDeparture from '../AttendanceDeparture/AttendanceDeparture';
import ResetPassword from '../Auth/ResetPassword';
import MonthlyAccounts from '../MonthlyAccounts/MonthlyAccounts';
import AllAttendanceDeparture from '../AttendanceDeparture/AllAttendanceDeparture';
import Reports from '../Reports/Reports';
import SimilarOrders from '../Orders/SimilarOrders';
import AttendanceRegister from '../AttendanceDeparture/AttendanceRegister';
import TransfersProducts from '../CustomersService/TransfersProducts';
import ClientsInterests from '../Clients/ClientsInterests';
import AddClientsInterests from '../Clients/AddClientsInterests';
import Doctors from '../Doctors/Doctors';
import AddDoctors from '../Doctors/AddDoctors';
import AbuQirBills from '../AbuQir/Bills/AbuQirBills';



function App() {

  let { saveUserData  } = useContext(AuthContext);
  
  return (
    <>
      <Offline> <div className='offline'>.أنت غير متصل بالإنترنت</div> </Offline>

      <BrowserRouter>
        <Routes>
          <Route index element={<Login saveUserData={saveUserData} />} ></Route>
          <Route path='/' element={< MasterLayout />}>
            <Route path='/home' element={<ProtectedRoute ><Home /></ProtectedRoute>} ></Route>
            <Route path='/users' element={<ProtectedRoute ><Users /></ProtectedRoute>} ></Route>
            <Route path='/users/add' element={<ProtectedRoute ><AddUser /></ProtectedRoute>} ></Route>
            <Route path='/users/delete/:id' element={<ProtectedRoute ><DeleteUser /></ProtectedRoute>} ></Route>
            <Route path='/users/edite/:id' element={<ProtectedRoute ><EditeUser /></ProtectedRoute>} ></Route>
            <Route path='/users/details/:id' element={<ProtectedRoute ><UserDetails /></ProtectedRoute>} ></Route>
            <Route path='clients' element={<ProtectedRoute ><Clients /></ProtectedRoute>} ></Route>
            <Route path='clients/add' element={<ProtectedRoute ><AddClient /></ProtectedRoute>} ></Route>
            <Route path='clients/delete/:id' element={<ProtectedRoute ><DeleteClient /></ProtectedRoute>} ></Route>
            <Route path='clients/edite/:id' element={<ProtectedRoute ><EditeClient /></ProtectedRoute>} ></Route>
            <Route path='clients/details/:id' element={<ProtectedRoute ><ClientDetails /></ProtectedRoute>} ></Route>
            <Route path='/orders' element={<ProtectedRoute ><Orders /></ProtectedRoute>} ></Route>
            <Route path='orders/delete/:id' element={<ProtectedRoute ><DeleteOrder /></ProtectedRoute>} ></Route>
            <Route path='orders/edite/:id' element={<ProtectedRoute ><EditeOrder /></ProtectedRoute>} ></Route>
            <Route path='salepoints' element={<ProtectedRoute ><SalePoints /></ProtectedRoute>} ></Route>
            <Route path='branches' element={<ProtectedRoute ><Branches /></ProtectedRoute>} ></Route>
            <Route path='shortcomings/add' element={<ProtectedRoute ><AddShortComings /></ProtectedRoute>} ></Route>
            <Route path='purchases' element={<ProtectedRoute ><Purchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/delete/:id' element={<ProtectedRoute ><DeletePurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/edite/:id' element={<ProtectedRoute ><EditePurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/details/:id' element={<ProtectedRoute ><PurchasesDeatils /></ProtectedRoute>} ></Route>
            <Route path='customersservice' element={<ProtectedRoute ><CustomersService /></ProtectedRoute>} ></Route>
            <Route path='addstatus' element={<ProtectedRoute ><AddStatus /></ProtectedRoute>} ></Route>
            <Route path='offers' element={<ProtectedRoute ><Offers /></ProtectedRoute>} ></Route>
            <Route path='offers/add' element={<ProtectedRoute ><AddOffer /></ProtectedRoute>} ></Route>
            <Route path='offers/delete/:id' element={<ProtectedRoute ><DeleteOffer /></ProtectedRoute>} ></Route>
            <Route path='offers/edite/:id' element={<ProtectedRoute ><EditeOffer /></ProtectedRoute>} ></Route>
            <Route path='companies' element={<ProtectedRoute ><Companies /></ProtectedRoute>} ></Route>
            <Route path='companies/add' element={<ProtectedRoute ><AddCompany /></ProtectedRoute>} ></Route>
            <Route path='companies/edite/:id' element={<ProtectedRoute ><EditeCompany /></ProtectedRoute>} ></Route>
            <Route path='rules' element={<ProtectedRoute ><Rules /></ProtectedRoute>} ></Route>
            <Route path='rules/add' element={<ProtectedRoute ><AddRules /></ProtectedRoute>} ></Route>
            <Route path='rules/edite/:id' element={<ProtectedRoute ><EditeRule /></ProtectedRoute>} ></Route>
            <Route path='inventoryproducts' element={<ProtectedRoute ><InventoryProducts /></ProtectedRoute>} ></Route>
            <Route path='inventoryproducts/add' element={<ProtectedRoute ><AddInventoryProducts /></ProtectedRoute>} ></Route>
            <Route path='notifications' element={<ProtectedRoute ><Notifications /></ProtectedRoute>} ></Route>
            <Route path='notifications/add' element={<ProtectedRoute ><AddNotification /></ProtectedRoute>} ></Route>
            <Route path='sellingincentives' element={<ProtectedRoute ><SellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='sellingincentives/add' element={<ProtectedRoute ><AddSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='sellingincentives/delete/:id' element={<ProtectedRoute ><DeleteSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='sellingincentives/edite/:id' element={<ProtectedRoute ><EditeSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='reasonsofincentives' element={<ProtectedRoute ><ReasonsOfIncentives /></ProtectedRoute>} ></Route>
            <Route path='/areas' element={<ProtectedRoute ><Areas /></ProtectedRoute>} ></Route>
            <Route path='/workpolicies' element={<ProtectedRoute ><WorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='/workpolicies/add' element={<ProtectedRoute ><AddWorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='/workpolicies/edite/:id' element={<ProtectedRoute ><EditeWorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='/poporder' element={<ProtectedRoute ><PopUpOrders/></ProtectedRoute>} ></Route>
            <Route path='/poporder/add' element={<ProtectedRoute ><AddPopUpOrders /></ProtectedRoute>} ></Route>
            <Route path='/abuqirclients' element={<ProtectedRoute >< AbuQirClients /></ProtectedRoute>} ></Route>
            <Route path='/abuqirclients/add' element={<ProtectedRoute >< AddAbuQirClients /></ProtectedRoute>} ></Route>
            <Route path='/abuqirforms' element={<ProtectedRoute >< AbuQirForms /></ProtectedRoute>} ></Route>
            <Route path='/abuqir-bills' element={<ProtectedRoute >< AbuQirBills /></ProtectedRoute>} ></Route>
            <Route path='/abuqir-bills/add' element={<ProtectedRoute >< AddAbuQirForms /></ProtectedRoute>} ></Route>
            <Route path='/abuqirforms/add' element={<ProtectedRoute >< AddAbuQirForms /></ProtectedRoute>} ></Route> 
            <Route path='/claimDetails' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='/employees' element={<ProtectedRoute >< Employees /></ProtectedRoute>} ></Route>
            <Route path='/employees/add' element={<ProtectedRoute >< AddEmployee /></ProtectedRoute>} ></Route>
            <Route path='/attendancedeparture' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='/allattendancedeparture' element={<ProtectedRoute >< AllAttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='/resetpassword' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='/monthlyaccounts' element={<ProtectedRoute >< MonthlyAccounts /></ProtectedRoute>} ></Route>
            <Route path='/reports' element={<ProtectedRoute >< Reports /></ProtectedRoute>} ></Route>
            <Route path='/similarorders' element={<ProtectedRoute >< SimilarOrders /></ProtectedRoute>} ></Route>
            <Route path='/transfersproducts' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>
            <Route path='/clientsinterests' element={<ProtectedRoute >< ClientsInterests /></ProtectedRoute>} ></Route>
            <Route path='/clientsinterests/add' element={<ProtectedRoute >< AddClientsInterests /></ProtectedRoute>} ></Route>
            <Route path='/doctors' element={<ProtectedRoute >< Doctors /></ProtectedRoute>} ></Route>
            <Route path='/doctors/add' element={<ProtectedRoute >< AddDoctors /></ProtectedRoute>} ></Route>

          </Route>
          <Route path='/' element={<ProtectedRoute><DeliveryLayout /></ProtectedRoute>}>
            <Route path='/deliveryOrders/:id' element={<ProtectedRoute><DeliveryOrders /></ProtectedRoute>}></Route>
            <Route path='/deliveryrules' element={<ProtectedRoute><DeliveryRules /></ProtectedRoute>}></Route>
            <Route path='/deliveryworkpolicies' element={<ProtectedRoute><DeliveryWorkPolicies /></ProtectedRoute>}></Route>
            <Route path='/deliverypoporders' element={<ProtectedRoute><DeliveryPopUpOrders /></ProtectedRoute>}></Route>
            <Route path='/deliveryresetpassword' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='/deliveryattendance' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
   
          </Route>
          <Route path='/' element={<ProtectedRoute><CasherLayout /></ProtectedRoute>}>
            <Route path='/allOrders' element={<ProtectedRoute><AllOrders /></ProtectedRoute>}></Route>
            <Route path='/popOrders' element={<ProtectedRoute><PopUpOrders /></ProtectedRoute>}></Route>
            <Route path='/popOrders/add' element={<ProtectedRoute><AddPopUpOrders /></ProtectedRoute>}></Route>
            <Route path='/clientData' element={<ProtectedRoute><ClientsData /></ProtectedRoute>}></Route>
            <Route path='/clientData/edite/:id' element={<ProtectedRoute><EditeClient /></ProtectedRoute>}></Route>
            <Route path='/allUsers' element={<ProtectedRoute><AllUsers /></ProtectedRoute>}></Route>
            <Route path='/casherabuqirclients' element={<ProtectedRoute>< AbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/casherabuqirclients/add' element={<ProtectedRoute>< AddAbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/casherabuqirforms' element={<ProtectedRoute>< AbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/casher-abuqir-bills' element={<ProtectedRoute>< AbuQirBills/></ProtectedRoute>}></Route>
            <Route path='/casher-abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/casherabuqirforms/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/casherclaimDetails' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='/casherresetpassword' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='/casherattendancedeparture' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='/casherattendance' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>

          </Route>

          <Route path='/' element={<ProtectedRoute><PurchasesLayout /></ProtectedRoute>}>
            <Route path='/purchasesuser' element={<ProtectedRoute><PurchasesUser /></ProtectedRoute>}></Route>
            <Route path='/purchasesuser/edite/:id' element={<ProtectedRoute><EditePurchasesUser /></ProtectedRoute>}></Route>
            <Route path='/purchasesuser/delete/:id' element={<ProtectedRoute><DeletePurchasesUser /></ProtectedRoute>}></Route>
            <Route path='/purchasesuser/details/:id' element={<ProtectedRoute><PurchasesUserDetails /></ProtectedRoute>}></Route>
            <Route path='/shortcomingspurchases/add' element={<ProtectedRoute><AddShortComings/></ProtectedRoute>}></Route>
            <Route path='/purchasesinventoryproducts' element={<ProtectedRoute><PurchasesInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='/purchasesinventoryproducts/add' element={<ProtectedRoute><AddPurchasesInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='/purchasescompanies' element={<ProtectedRoute><PurchasesCompanies /></ProtectedRoute>}></Route>
            <Route path='/purchasescompanies/add' element={<ProtectedRoute><AddPurchasesCompanies /></ProtectedRoute>}></Route>
            <Route path='/purchasescompanies/edite/:id' element={<ProtectedRoute><EditePurchasesCompanies /></ProtectedRoute>}></Route>
            <Route path='/purchasescustomerservice' element={<ProtectedRoute><CustomersService /></ProtectedRoute>}></Route>
            <Route path='/purchasesnotifications' element={<ProtectedRoute><PurchasesNotifications /></ProtectedRoute>}></Route>
            <Route path='/purchasesnotifications/add' element={<ProtectedRoute><AddPurchasesNotifications /></ProtectedRoute>}></Route>
            <Route path='/purchasesabuqirclients' element={<ProtectedRoute>< AbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/purchasesabuqirclients/add' element={<ProtectedRoute>< AddAbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/purchasesabuqirforms' element={<ProtectedRoute>< AbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/purchases-abuqir-bills' element={<ProtectedRoute>< AbuQirBills/></ProtectedRoute>}></Route>
            <Route path='/purchasesabuqirforms/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/purchases-abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/purchasesclaimDetails' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='/purchasesresetpassword' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='/purchasesattendance' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
            <Route path='/purchasestransfersproducts' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>

   
          </Route>
          <Route path='/' element={<ProtectedRoute><DoctorLayout /></ProtectedRoute>}>
            <Route  path='/doctorpurchases' element={<ProtectedRoute ><DoctorPurchases /></ProtectedRoute>} ></Route>
            <Route path='/doctorpurchases/add' element={<ProtectedRoute ><AddShortComings /></ProtectedRoute>} ></Route>
            <Route path='/doctorpurchases/details/:id' element={<ProtectedRoute><DoctorPurchasesDeatils /></ProtectedRoute>} ></Route>
            <Route path='/doctorpurchases/edite/:id' element={<ProtectedRoute><EditePurchasesDoctor /></ProtectedRoute>} ></Route>
            <Route  path='/doctorOrders/:id' element={<ProtectedRoute><DoctorOrders /></ProtectedRoute>}></Route>
            <Route path='/doctorOrders/edite/:id' element={<ProtectedRoute><EditeOrder /></ProtectedRoute>}></Route>
            <Route path='/doctorOrders/details/:id' element={<ProtectedRoute><OrderDetails /></ProtectedRoute>}></Route>
            <Route path='/doctoroffers' element={<ProtectedRoute><DoctorOffers /></ProtectedRoute>}></Route>
            <Route path='/doctoroffers/add' element={<ProtectedRoute><AddOfferDoctor /></ProtectedRoute>}></Route>
            <Route path='/doctoroffers/edite/:id' element={<ProtectedRoute><EditeOffersDoctor /></ProtectedRoute>}></Route>
            <Route path='/doctornotifications' element={<ProtectedRoute><DoctorNotifications /></ProtectedRoute>}></Route>
            <Route path='/doctornotifications/add' element={<ProtectedRoute><AddDoctorNotifications /></ProtectedRoute>}></Route>
            <Route path='/doctorinventoryproducts' element={<ProtectedRoute><DoctorInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='/doctorinventoryproducts/add' element={<ProtectedRoute><AddDoctorInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='/doctorrules' element={<ProtectedRoute><DoctorRules /></ProtectedRoute>}></Route>
            <Route path='/doctorsellingincentives' element={<ProtectedRoute><DoctorSellingIncentives /></ProtectedRoute>}></Route>
            <Route path='/doctorcustomerservice' element={<ProtectedRoute><CustomersService /></ProtectedRoute>}></Route>
            <Route path='/doctorclients' element={<ProtectedRoute><Clients /></ProtectedRoute>}></Route>
            <Route path='/doctorclients/edite/:id' element={<ProtectedRoute><EditeClient/></ProtectedRoute>}></Route>
            <Route path='/doctorworkpolicies' element={<ProtectedRoute><DoctorWorkPolicies/></ProtectedRoute>}></Route>
            <Route path='/doctorpoporders' element={<ProtectedRoute><DoctorPopUpOrders/></ProtectedRoute>}></Route>
            <Route path='/doctorpoporders/add' element={<ProtectedRoute><AddPopUpOrdersDoctor/></ProtectedRoute>}></Route>
            <Route path='/doctorabuqirclients' element={<ProtectedRoute>< AbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/doctorabuqirclients/add' element={<ProtectedRoute>< AddAbuQirClients/></ProtectedRoute>}></Route>
            <Route path='/doctorabuqirforms' element={<ProtectedRoute>< AbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/doctor-abuqir-bills' element={<ProtectedRoute>< AbuQirBills/></ProtectedRoute>}></Route>
            <Route path='/doctor-abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/doctorabuqirforms/add' element={<ProtectedRoute>< AddAbuQirForms/></ProtectedRoute>}></Route>
            <Route path='/doctorclaimDetails' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='/doctorresetpassword' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='/doctorattendancedeparture' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='/doctorattendance' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
            <Route path='/doctortransfersproducts' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>
            <Route path='/doctors-nursing' element={<ProtectedRoute >< Doctors /></ProtectedRoute>} ></Route>
            <Route path='/doctors-nursing/add' element={<ProtectedRoute >< AddDoctors /></ProtectedRoute>} ></Route>


          </Route>
          <Route path='*' element={<ProtectedRoute ><NotFound /></ProtectedRoute>}></Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position='top-left'
        autoClose={5000}
      />

    </>
  );
}

export default App;
