import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import Joi from 'joi';
import { toast } from 'react-toastify';

export default function EditeDoctor({ doctorId , getDoctorsData}) {
    let { accessToken } = useContext(AuthContext);
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [editedDoctor, setEditedDoctor] = useState({
        name: '',
        specialization: '',
        degree: '',
        contactInfo: '',
    });
    let [oneDoctor, setOneDoctor] = useState([])
    let getOneDoctor = async () => {
        if (doctorId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/doctors/show/${doctorId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });        
            setOneDoctor(data);
            setEditedDoctor({
                name: data.name || '',
                specialization:data.specialization || '',
                degree: data.degree || '',
                contactInfo: data.contactInfo || '',
            });
        }
    };
    useEffect(() => {
        getOneDoctor()
    }, [doctorId]);

    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedDoctor(prevState => ({ ...prevState, [name]: value }));
    };

    let sendEditedDoctorToApi = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}`, editedDoctor, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            formRef.current.reset();
            getDoctorsData()
            setEditedDoctor({
                name: '',
                specialization: '',
                degree: '',
                contactInfo: '',
            })
            getOneDoctor()
        }).catch((errors) => {
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error("حدث خطأ ما");
            }

        });
    };
    let validateEditedDoctorForm = () => {
        const schema = Joi.object({
            name: Joi.string().allow(''),
            specialization: Joi.string().allow(''),
            degree: Joi.string().allow(''),
            contactInfo: Joi.string().allow(''),

        });
        return schema.validate(editedDoctor, { abortEarly: false });
    };

    let submitEditedDoctor = (e) => {
        e.preventDefault();
        let validation = validateEditedDoctorForm();
        if (!validation.error) {
            sendEditedDoctorToApi();
        } else {

            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };
    return (
        <>
            <form ref={formRef} onSubmit={submitEditedDoctor} >
                <div className="row gy-3">
                    <div className="col-md-5">
                        <label htmlFor="name" className='form-label'>اسم الطبيب </label>
                        <input
                            type="text"
                            className='form-control'
                            name="name"
                            onChange={handleInputChange}
                            defaultValue={oneDoctor?.name}
                             />
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="specialization" className='form-label'>التخصص</label>
                        <input
                            type="text"
                            className='form-control'
                            name="specialization"
                            onChange={handleInputChange}
                            defaultValue={oneDoctor?.specialization}
                             />
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="degree" className='form-label'> الدرجة العلمية  </label>
                        <input
                            type="text"
                            className='form-control'
                            name="degree"
                            onChange={handleInputChange}
                            defaultValue={oneDoctor?.degree}
                             />
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="contactInfo" className='form-label'> العنوان والهاتف</label>
                        <input
                            type="text"
                            className='form-control'
                            name="contactInfo"
                            onChange={handleInputChange} 
                            defaultValue={oneDoctor?.contactInfo}
                            />
                    </div>
                    <div className="col-md-3">
                        <button type='submit' className='btn btn-primary form-control fs-5' data-bs-dismiss="modal">
                            {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                        </button>
                    </div>

                </div>
            </form >
        </>
    )
}
