import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function OrderDetails({ orderId }) {
  let { accessToken } = useContext(AuthContext);
  let [oneOrders, setOneOrders] = useState([]);
  let getOneOrder = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/show/${orderId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setOneOrders(data.data);
    } catch (error) {
      toast.error('حدث خطأ ما')
    }
  };
  useEffect(() => {
    getOneOrder()
  }, [orderId]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order Details</title>
      </Helmet>
      <div className="row m-2">
        <p> <strong>  كود الطيار :</strong> <span>{oneOrders?.delivery_man?.code}</span></p>
        <p> <strong>  اسم الطيار :</strong> <span>{oneOrders?.delivery_man?.name} </span></p>
        <p> <strong>  كود العميل :</strong> <span>{oneOrders?.customer?.code} </span></p>
        <p> <strong>  اسم العميل :</strong> <span>{oneOrders?.customer?.name} </span></p>
        <p> <strong>  هاتف العميل :</strong> <span> {oneOrders?.customer_phone}  </span></p>
        <p> <strong>  تاريخ سداد الطيار :</strong> <span> {oneOrders?.payed_at}  </span></p>
        <p> <strong>  عنوان العميل  :</strong> <span>{oneOrders?.customer_address} </span></p>
        <p> <strong>   حالة الأوردر  :</strong> <span>{oneOrders?.status?.name} </span></p>
        <p> <strong>   قيمة الأوردر  :</strong> <span>{oneOrders?.cost} </span></p>
        <p> <strong>    إجمالي المبلغ  :</strong> <span> {oneOrders?.total_ammount} </span></p>
        <p> <strong>     ملاحظات  :</strong> <span>  {oneOrders?.notes} </span></p>
      </div>
    </>
  )
}
