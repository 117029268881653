// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;

}

a,
span {
  cursor: pointer;
}


body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(245, 240, 240);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: right;


}

form {
  direction: rtl;
}

.card {
  direction: rtl;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.height-calc-70 {
  height: calc(100vh - 166px) !important;
}

.no-wrap-heading th {
  white-space: nowrap;

}

ul li span,
ul li i {
  color: black;
}

.nav-item .active {
  background-color: rgb(100, 100, 128);
  border-radius: 10px;
}

.nav-item .active i,
.nav-item .active span {
  color: white !important;
}





/* table tbody td {
  min-width: 150px;
} */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;;;EAGE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,gCAAgC;;AAElC;;AAEA;;EAEE,eAAe;AACjB;;;AAGA;EACE,mJAAmJ;EACnJ,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;;EAElC,iBAAiB;;;AAGnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE;aACW;AACb;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;;AAErB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;;EAEE,uBAAuB;AACzB;;;;;;AAMA;;GAEG","sourcesContent":["/* Global Styles */\n*,\n*::after,\n*::before {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  text-decoration: none !important;\n\n}\n\na,\nspan {\n  cursor: pointer;\n}\n\n\nbody {\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n  background-color: rgb(245, 240, 240);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n  text-align: right;\n\n\n}\n\nform {\n  direction: rtl;\n}\n\n.card {\n  direction: rtl;\n}\n\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.height-calc-70 {\n  height: calc(100vh - 166px) !important;\n}\n\n.no-wrap-heading th {\n  white-space: nowrap;\n\n}\n\nul li span,\nul li i {\n  color: black;\n}\n\n.nav-item .active {\n  background-color: rgb(100, 100, 128);\n  border-radius: 10px;\n}\n\n.nav-item .active i,\n.nav-item .active span {\n  color: white !important;\n}\n\n\n\n\n\n/* table tbody td {\n  min-width: 150px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
