import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore'; import { toast } from 'react-toastify';
;

export default function AttendanceRegister() {
  let { accessToken } = useContext(AuthContext);
  let [attendanceDeparture, setAttendanceDeparture] = useState([]);
  let getAttendanceDepartureData = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/attendance/attendanceRecords`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setAttendanceDeparture(data.data);
    } catch (error) {
      toast.error('حدث خطأ ما')
    }
  };

  useEffect(() => {
    getAttendanceDepartureData();
  }, []);
  // Helper function to format time
  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${ampm}`;
  };
  let showAttendanceDeparture = () => {
    if (attendanceDeparture.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white m-3 p-3 table-responsive">
          <table dir="rtl" className='table table-hover align-middle text-center table-responsive-list'>
            <thead className='table-primary no-wrap-heading'>
              <tr>
                <th>التاريخ</th>
                <th>اليوم</th>
                <th>وقت الحضور</th>
                <th>وقت الانصراف</th>
                <th>ساعات الحضور</th>
                <th>تأخير</th>
              </tr>
            </thead>
            <tbody>
              {attendanceDeparture.map((emp) => (
                <tr key={emp.id}>
                  <td data-label="التاريخ">{emp.date}</td>
                  <td data-label="اليوم">
                    {emp.day === 'Saturday' ? 'السبت' :
                      emp.day === 'Sunday' ? 'الأحد' :
                        emp.day === 'Monday' ? 'الإثنين' :
                          emp.day === 'Tuesday' ? 'الثلاثاء' :
                            emp.day === 'Wednesday' ? 'الأربعاء' :
                              emp.day === 'Thursday' ? 'الخميس' :
                                emp.day === 'Friday' ? 'الجمعة' : ''}
                  </td>
                  <td data-label="وقت الحضور">{emp.attendance_time ? formatTime(emp.attendance_time) : '-'}</td>
                  <td data-label="وقت الانصراف">{emp.departure_time ? formatTime(emp.departure_time) : '-'}</td>
                  <td data-label=" ساعات الحضور">{emp?.working_hours}</td>
                  <td data-label="تأخير">{emp?.lateHours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className='d-flex justify-content-center height-calc-70 align-items-center'>
          <i className='fa fa-spinner fa-spin fa-2x'></i>
        </div>
      );
    }
  };



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Attendance Departure</title>
      </Helmet>
      <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>سجل الحضور والإنصراف</h3>
      {showAttendanceDeparture()}
    </>
  );
}
