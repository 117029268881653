import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import EditeDoctor from './EditeDoctor';

export default function Doctors() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [doctorsData, setDoctorsData] = useState([]);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [searchText, setSearchText] = useState('');
    function handleSearchChange(event) {
        setSearchText(event.target.value);
    };

    let getDoctorsData = async (page = 1) => {
        let doctorResult;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/doctors`;
        if (searchText !== undefined && searchText.trim().length > 0) {
            urlApi += `/search/${searchText}`
        }
        urlApi += `?page=${page}`
        doctorResult = await axios.get(urlApi, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        if (doctorResult) {
            setDoctorsData(doctorResult.data.data);
            setPagination(doctorResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        }

    }

    useEffect(() => {
        getDoctorsData()
    }, [searchText])
    //for handle page change
    let handlePageChange = (page) => {
        getDoctorsData(page);
    };
    let deleteDoctor = async (doctorId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/doctors/delete/${doctorId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getDoctorsData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    let [doctorId, setDoctorId] = useState('');
    let showDoctors = () => {
        if (doctorsData.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <table dir="rtl" className='table  table-hover align-middle text-center table-responsive-list  '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th>الاسم</th>
                                <th>التخصص</th>
                                <th> الدرجة العلمية </th>
                                <th>العنوان والهاتف</th>
                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {doctorsData.map((doc) => <tr key={doc.id}>
                                <td data-label="الاسم ">{doc?.name}</td>
                                <td data-label="التخصص">{doc?.specialization}</td>
                                <td data-label="الدرجة العلمية">{doc?.degree}</td>
                                <td data-label="العنوان والهاتف">{doc?.contactInfo}</td>

                                <td data-label="خيارات" >
                                    <div className="dropdown">
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setDoctorId(doc.id)
                                        }} data-bs-toggle="modal" data-bs-target="#editeDoctor"></i>
                                        <span data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bi bi-trash text-danger fs-5 ' ></i>
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => { deleteDoctor(doc.id) }}>حذف</button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {doctorsData.length <= 0 && searchText.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-2x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق لهذا البحث</div>
                    }
                </div>
            )
        }
    };


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Doctors </title>
            </Helmet>
            <div className=" m-4 text-center row" dir='rtl'>
                <div className="col-md-6">
                    <input type="text" className='form-control text-end' onChange={handleSearchChange} placeholder='بحث...' />
                </div>
                <div className="col-md-6">
                    {currentPath === '/doctors' && (
                         <NavLink to='/doctors/add' className='btn btn-primary mb-1'>إضافة أطباء</NavLink>
                    )}
                    {currentPath === '/doctors-nursing' && (
                        <NavLink to='/doctors-nursing/add' className='btn btn-primary my-2 '>إضافة أطباء</NavLink>
                    )}
                  
                </div>
            </div>

            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showDoctors()}
            {/* edite doctor modal */}
            <div dir='rtl' className="modal fade " id="editeDoctor" tabIndex={-1} aria-labelledby="editeDoctorLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="editeDoctorLabel"> تعديل بيانات الطبيب</h1>
                        </div>
                        <div className="modal-body">
                            <EditeDoctor doctorId={doctorId} getDoctorsData={getDoctorsData} />
                            <div className="my-2">
                                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
