import React from 'react'
import AddDoctorNotifications from '../../Doctor/Notifications/AddDoctorNotifications'
import AddNotification from '../../Notifications/AddNotification'

export default function AddPurchasesNotifications() {
  return (
   <>
  <AddNotification/>
   </>
  )
}
