
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import Joi from 'joi';
import Select from 'react-select';

export default function PopUpOrders() {
  let { accessToken } = useContext(AuthContext);
  const areaSelectRef = useRef(null);
  const formRef = useRef(null)
  let [pagination, setPagination] = useState(null);
  let [currentPage, setCurrentPage] = useState(1); // Current page state
  let [orders, setOrders] = useState([]);
  let getOrderData = async (page = 1) => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/unAcceptedOrders?page=${page}`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setOrders(data.data);
    setPagination(data.meta); // Set pagination data
    setCurrentPage(page); // Set current page
  }
  useEffect(() => { getOrderData() }, []);
  //for handle page change
  let handlePageChange = (page) => {
    getOrderData(page);
  };
  let deleteOrder = async (orderId) => {
    try {
      let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/delete/${orderId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      toast.success(data.message);
      getOrderData()

    } catch (error) {
      toast.error('حدث خطأ ما، حاول مرة أخرى')
    }
  };
  let [orderId, setOrderId] = useState('');
  let showOrders = () => {
    if (orders.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
          <table dir="rtl" className='table  table-hover text-center align-middle table-responsive-list '>
            <thead className='table-primary  no-wrap-heading'>
              <tr>
                <th> تاريخ الإنشاء</th>
                <th>مُنشئ الأوردر</th>
                <th>كود العميل</th>
                <th> قيمة الأوردر</th>
                <th> نقطة اليبع</th>
                <th>المنطقة </th>
                <th>حالة الأوردر </th>
                <th> ملاحظات </th>
                <th>خيارات </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => <tr key={order.id}>
                <td data-label="تاريخ الإنشاء"  >{order.created_at}</td>
                <td data-label="مُنشئ الأوردر"  >{order.creatorUser?.name}</td>
                <td data-label="كود العميل">{order?.customer?.code}</td>
                <td data-label="كود العميل">{order?.cost}</td>
                <td data-label="نقطة البيع">{order?.sale_point?.name}</td>
                <td data-label=" المنطقة">{order?.area?.name}</td>
                <td data-label=" حالة الأوردر">{order?.status?.name}</td>
                <td data-label="ملاحظات">{order?.notes}</td>
                <td data-label=" خيارات">
                  <i className='bi bi-trash text-danger fs-5' onClick={() => { deleteOrder(order.id) }}></i>
                  <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                    setOrderId(order.id)
                  }} data-bs-toggle="modal" data-bs-target="#exampleModal"></i>

                </td>
              </tr>

              )}
            </tbody>

          </table>
        </div>
      )
    } else {
      return (
        <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
          <div className='alert alert-danger w-50 text-center'>لا يوجد أوردرات</div>
        </div>
      )
    }
  };
  // edite order
  let [editedOrder, setEditedOrder] = useState({
    customer_code: '',
    area_id: '',
    cost: '',
    status_id: ''
  });
  // for geeting order status
  let [orderStatus, setOrderStatus] = useState([]);
  let getOrderStatus = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/orderStatus`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setOrderStatus(data)
  }
  useEffect(() => {
    getOrderStatus()
  }, []);
  let [orderData, setOrderData] = useState([])
  let getOneOrder = async () => {
    if (orderId) {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/show/${orderId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
 
      setOrderData(data.data);
      setEditedOrder({
        customer_code: data.data?.customer?.code || '',
        cost: data.data?.cost || '',
        area_id: data.data?.area?.id || '',
        status_id: data?.data?.status?.id || ''
      });
    }

  };
  useEffect(() => {
    getOneOrder()
  }, [orderId]);

  //getting areas data to display in select 
  let [areasData, setAreasData] = useState([]);
  let getAreasData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/areas`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setAreasData(data);
    } catch (error) {
      toast.error('حدث خطأ أثناء جلب البيانات');
    }
  }
  useEffect(() => {
    getAreasData();
  }, []);
  //making map on the areas data to display the name in the option
  let [areasOptions, setAreasOptions] = useState([])
  useEffect(() => {
    let mapAreas = areasData?.map((area) => ({
      value: `${area.id}`,
      label: `${area.name}`
    }));
    setAreasOptions(mapAreas);
  }, [areasData]);
  let getSelectedArea = (selectedOption) => {
    setEditedOrder({
      ...editedOrder,
      area_id: selectedOption?.value,
    });
  };
  let getInputValue = (event) => {
    let myOrders = { ...editedOrder }; //deep copy
    myOrders[event.target.name] = event.target.value;
    setEditedOrder(myOrders);

  };
  let sendEditedDataToApi = async () => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, editedOrder, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message, {
        position: 'top-center'
      });
      formRef.current.reset();
      areaSelectRef.current.clearValue();
      setEditedOrder({
        customer_code: '',
        area_id: '',
        cost: '',
        status_id:''
      })
      getOrderData()

    }).catch((errors) => {

      try {
        const errorList = errors?.response?.data?.message;
        if (errorList !== undefined) {
          Object.keys(errorList)?.map((err) => {
            errorList[err]?.map((err) => {
              toast.error(err);
            })
          });
        } else {
          toast.error("حدث خطأ ما");
        }
      } catch (error) {
        toast.error("حدث خطأ ما");
      }

    });
  };

  let validateEditedFrom = () => {
    const schema = Joi.object({
      customer_code: Joi.string().empty(''),
      cost: Joi.number().empty(''),
      area_id: Joi.any().empty(''),
      status_id: Joi.any().empty(''),

    });
    return schema.validate(editedOrder, { abortEarly: false });
  };

  let editedOrederSubmit = (e) => {
    e.preventDefault();
    let validation = validateEditedFrom();
    if (!validation.error) {
      sendEditedDataToApi();
    } else {

      try {
        validation.error.details.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");
      }
    }

  };



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
      </Helmet>
      <div>
        <p className='alert alert-danger m-3 text-center fw-bold fs-4' >الأوردرات المعلقة</p>
      </div>
      {/* edite modal */}
      <div dir='rtl' className="modal fade " id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5  m-auto" id="exampleModalLabel"> تعديل الأوردر</h1>
            </div>
            <div className="modal-body">
              <form ref={formRef} onSubmit={editedOrederSubmit} >
                <div className="row gy-3">
                  <div className="col-md-4">
                    <label htmlFor="customer_code" className='form-label'>كود العميل  </label>
                    <input type="text" name="customer_code" id="customer_code" className='form-control '
                      onChange={getInputValue}
                      defaultValue={orderData?.customer?.code}

                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="cost" className='form-label'>قيمة الأوردر </label>
                    <input type="text" className='form-control ' name="cost" id="cost"
                      onChange={getInputValue}
                      defaultValue={orderData?.cost}

                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="status_id" className='form-label'>حالة الأوردر</label>
                    <select name="status_id" defaultValue={0} className='form-control' id="status_id"
                      onChange={getInputValue}>
                      <option value={0} hidden disabled>اختر..</option>
                      {orderStatus.map((ord) => <option key={ord.id} value={ord?.id}>{ord?.name}</option>)}
                    </select>
                  </div>
                  <div className='col-md-4'>
                    <label htmlFor="areas" className='form-label'>المنطقة</label>
                    <Select
                      name="areas"
                      ref={areaSelectRef}
                      options={areasOptions}
                      onChange={getSelectedArea}
                      isSearchable={true}
                      placeholder="بحث عن منطقة..."

                    />
                  </div>
                </div>
                <div className="col-md-3 my-4">
                  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">تعديل </button>
                </div>
              </form >
              <div className="my-2">
                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-2">
        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      {showOrders()}
    </>
  )
}
